<template lang="">
 <el-dialog  
    :visible="true"
    :show-close="false"
    :close-on-press-escap="false"
    fullscreen
    top="0"
    class="notice-dialog"
    >
    <div class="notice-panel-container">
        <div class="logo"><img src="/img/logo.png" alt=""></div>
        <h1 >Tech Support Ticket Notice </h1>
        <div class="notice-content">
            <h2 >Tech support ticket scope</h2>
            <p > The warranty process will only be provided to those products that are purchased from Seeed Online Store and Seeed indicated legitimate distributors. Tickets should be created with proof of purchase and legitimate order numbers.&nbsp;</p>
            <p>Devices purchased from any individuals and third parties such as Amazon, eBay will not be covered in the warranty policy.</p>
            <p>Please make sure you buy the product through legal channels to get a proper warranty.</p>
            
            <h2>Before creating a tech support ticket</h2>
            <p> Please make sure you have contacted our tech support and get a consensus on the after-sales solution. After you submit the ticket, please inform the tech support you contacted, and we will review and verify the ticket soon. No returns will be accepted without having previously obtained a return authorization. </p>
            
            <h2 >Ticket Processing Time</h2>
            <p >For repair cases, the repair is supposed to be completed within <b>20 working days</b> after we received the return package.</p>
            <p >For replacement cases, we will arrange the shipment in <b>5 working days</b> after we receive the returned defective device package.</p>
            <p >For replacement parts cases, we will arrange the shipment in <b>5 working days</b> after the ticket is completed. </p>
            
            <h2 > Shipment</h2>
            <p>Shipment will be sent by DHL Express from Shenzhen, China. Normally it will take <b>4-7 working days</b> to arrive. However, it also depends on the customs clearance process and the shipping distance.</p>
            <p>You are able to check your tech support ticket status using the original link and SN during the whole process.</p>
            
            <!-- <h2 > Product Return Guide</h2>
            <li><i class="el-icon-document"></i><a target="_blank" href="https://docs.google.com/document/d/1-EI4_PXVGE14iTdGM9AqwhBYMBYAmKoi--ytboPgg1k/edit?usp=sharing">SenseCAP M1 Return Information</a></li>
            <li><i class="el-icon-document"></i><a target="_blank" href="https://docs.google.com/document/d/1D-2Nh77rxB1Xl4VXTllg5BR5X-UEnKfm/edit?usp=sharing&amp;ouid=108441619004956477905&amp;rtpof=true&amp;sd=true">SenseCAP M1 Return Guide on Free DHL Delivery</a></li>
            <p> Note: Please make sure it is a hardware defect and get a consensus with tech support that the fee will be bound by Seeed before using this guide to return the device.  </p>
            <br/> -->
            
        </div>
        <div class="notice-opera">
            <el-divider/>
            <div class="read-check"><el-checkbox v-model="checked" >I have read and acknowledged the above notice</el-checkbox></div> 
            <div class="next-step"><el-button :type="checked ? 'primary':'info'" :disabled="!checked" @click="readNotice(checked)">Continue</el-button></div>
        </div>
       
        
    </div>
    </el-dialog>
</template>
<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import {
  MSG_A,
  MSG_B,
  MSG_C,
  MSG_D0,
  MSG_D1,
  MSG_D2,
  MSG_D3,
} from "@/store/index";
export default {
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    ...mapGetters(["FORM_DATA", "MESSAGE_CODE"]),
  },
  watch: {
    MESSAGE_CODE(code) {
      this.msgCode = code;
    },
  },
  methods: {
    ...mapMutations(["readNotice"]),
  },
};
</script>
<style lang="scss">
.notice-dialog{
    .el-dialog__header{display: none;}
    .el-dialog__body{
        padding: 0 0 0 0;
    }
}
.notice-panel-container {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  text-align: center;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
   
    h1{
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 10;
        font-size: 28px;
        color: #000;
        font-weight: bold;
        text-align: center;
        letter-spacing: 1px;
        padding: 20px 20px;
        margin: 0 0;
         @media screen and (max-width:768px) {
            font-size: 22px;
        }
    }
    .read-check .el-checkbox__inner{
        border-color: #666;
    }
    .read-check .el-checkbox .el-checkbox__label{
        font-size: 14px;
        color: #666;
    }
    .notice-opera{
        box-sizing: border-box;
        width: 100%;
        padding: 0 20px 20px 20px;
        position: sticky;
        bottom: 0;
        background: #fff;
        position: 10;
        @media screen and (max-width:768px) {
            padding: 0 20px 15px 20px;
        }
        .el-divider{
            margin: 20px 0;
        }
    }
    .next-step {
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
    .el-button {
        width: 200px;
    }
    }

  .notice-content{
      text-align: left;
      color: #000;
      padding: 0 15px 0 15px;
        @media screen and (max-width:768px) {
            padding: 0 10px 20px 10px;
        }
      h2{
          font-size: 20px;
          color: #000;
          font-weight: bold;
          padding: 5px 0 5px 0;
          margin: 15px 0 5px 0;
           @media screen and (max-width:768px) {
                font-size: 18px;
          }
      }
      p{
           font-size: 14px;
           color: #000;
           padding: 0 0 0 0;
           line-height: 20px;
           margin: 0 0 8px 0 ;
           @media screen and (max-width:768px) {
                line-height: 18px;
                font-size: 14px;
                margin: 0 0 5px 0 ;
          }
      }
      b{
          font-weight: bold;
      }
      li{
          list-style: none;
           font-size: 15px;
           font-weight: bold;
           color: #000;
           line-height: 23px;
           padding: 0 10px 0 0 ;
           margin:  0 0 5px 0;
            @media screen and (max-width:768px) {
                line-height: 20px;
                font-size: 14px;
                padding: 5px  0;
            }
           .el-icon-document{
               color: #00946c;
               margin-right: 10px;
           }
           a{
               text-decoration: underline;
           }
      }
      a{
          text-decoration: none;
          color: #00946c;
      }
      .note{
          font-size: 16px;
          font-weight: bold;
          line-height: 25px;
          margin-top: 15px;
      }
  }
}

</style>