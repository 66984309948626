import axios from 'axios';
import Vue from 'vue';
import qs from 'qs';

const env = process.env.NODE_ENV;
const getParams = function () {
  let search = location.search.substr(1),
    obj = {};
  if (!search) return obj;
  search = search.split("&");
  for (let i = 0; i < search.length; i++) {
    let item = search[i];
    item = item.split('=');
    if (item.length == 2) {
      obj[item[0]] = item[1];
    }
  }
  return obj;
}
let Token = getParams()['ticket'];


  /* Axios 配置 */

axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    return Promise.reject(error.response)
  }
)

axios.interceptors.request.use(config => {
  if(!Token) return;
  if(config.method == 'post'){
    config.data = qs.stringify({
      ...config.data,
      Token
    })
  }
  return config
})

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.timeout = 60000;


Vue.prototype.$http = axios;

let baseUrl = '';
if (env === 'development') {
  baseUrl = `http://k3test.seeed.cc:999/api/AfterSaleService`;
} else {
  baseUrl = `https://k3cloud-api.seeedstudio.com/api/AfterSaleService`;
}
// baseUrl = `http://192.168.1.181/K3cloud/services/SeeedAfterSaleService.asmx`
// baseUrl = `http://192.168.4.86/K3cloud/services/SeeedAfterSaleService.asmx`
// baseUrl = `http://k3test.seeed.cc:999/api/AfterSaleService`
baseUrl = `https://k3cloud-api.seeedstudio.com/api/AfterSaleService`





export default {
  /** 
   * GetWorkOrder 查询工单状态 
  */
  getWorkOrder() {
    return axios.post(`${baseUrl}/GetWorkOrder`)
  },

  /** 
   * 工单操作
    * CreateWorkOrder 创建
    * SaveWorkOrder	  用户暂存
    * SubmitWorkOrder	用户提交
    * UpdateWorkOrder	修改 
  */
  operaWorkOrder(name,data){
    return axios.post(`${baseUrl}/${name}`,{Data:JSON.stringify(data)})
  },

  /** 
   * FAE审核
  */
  approveWorkOrder(data) {
    return axios.post(`${baseUrl}/ApproveWorkOrder`,{...data})
  },

}