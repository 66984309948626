
<template>
  <div class="form-container" v-if="formData">
  <el-form
    ref="supportForm"
    :model="formData"
    label-position="left"
    size="med"
    id="support-form"
    class="m1-form"
    inline
    :rules="formRules"
    :disabled="disabledForm"
    :validate-on-rule-change="true"
  >
    <p id="ticketNumber" v-if="FORM_DATA.WorkOrderNo">Aftersale Ticket Number : <span>{{FORM_DATA.WorkOrderNo}}</span> </p>
    <!-- 售后类型 -->
    <el-divider content-position="left"><h3>Aftersale Type</h3></el-divider>
    <div class="form-item-group aftersale-type">
      <el-form-item label="Aftersale Type" prop="AfterSaleType">
        <el-select 
            :disabled="disabledItem" 
            v-model="formData.AfterSaleType" 
            placeholder="Aftersale Type"
            >
          <el-option
            v-for="item in aftersaleTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!-- level 2 AfterSaleType -->
      <el-form-item
        v-if="formData.AfterSaleType === 'Replace'"
        prop="IsReplaceReturn"
      >
        <el-select
          :disabled="disabledItem" 
          v-model="formData.IsReplaceReturn"
          placeholder="Need to return ?"
        >
          <el-option
            v-for="item in replaceOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <template
        v-if="IS_NEED_RETURN"
      >
        <el-form-item prop="ReturnPayer">
          <el-select
            :disabled="disabledItem"
            placeholder="Return fee"
            v-model="formData.ReturnPayer"
          >
            <el-option
              v-for="item in returnFeeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>

      <template v-if="formData.AfterSaleType === 'Send Accessories'">
        <el-form-item prop="SendAccessory">
          <el-select
            :disabled="disabledItem"
            v-model="formData.SendAccessory"
            placeholder="Send Accessories"
          >
            <el-option
              v-for="item in accessoriesOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- level 3 SendAccessory -->
        <el-form-item
          v-if="formData.SendAccessory === 'Antenna'"
          prop="AntennaModel"
        >
          <el-select
            :disabled="disabledItem"
            v-model="formData.AntennaModel"
            placeholder="Send Accessories"
          >
            <el-option
              v-for="item in antennaOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="formData.SendAccessory === 'Other'"
          prop="OtherAccessory"
        >
          <el-input
            :disabled="disabledItem"
            v-model="formData.OtherAccessory"
            placeholder="Others"
            clearable
          ></el-input>
        </el-form-item>
      </template>

      <el-alert
          v-if="formData.AfterSaleType === 'Replace' && formData.IsReplaceReturn === '0'"
          class="form-item-tip"
          title="Please make sure you have consulted with tech support and get approval on replacement without return"
          type="warning"
          :closable="false"
          effect="light"
          icon="el-icon-warning"
          show-icon>
      </el-alert>

      <el-alert
          v-if="IS_NEED_RETURN && formData.ReturnPayer === 'Seeed'"
          class="form-item-tip"
          title="Please make sure it is a hardware defect and get a consensus with tech support that the fee will be bound by Seeed before using this guide to return the device."
          type="warning"
          :closable="false"
          effect="light"
          icon="el-icon-warning"
          show-icon>
      </el-alert>
    </div>

    <el-divider content-position="left"><h3>Product</h3></el-divider>

    <div class="form-item-group">
      <el-form-item label="Product Name" >
        <el-select value="SenseCAP M1" :disabled="disabledItem" >
          <el-option value="SenseCAP M1" lable="SenseCAP M1"></el-option>
        </el-select>
      </el-form-item>
    </div>

    <!-- SN Number -->
    <div class="form-item-group SN-number">
      <el-form-item
        ref="SN_number"
        label="Serial Number (SN)" prop="SNCode">
        <el-input v-model="formData.SNCode"  :disabled="disabledItem"></el-input>
      </el-form-item>
    </div>

    <!-- Support Channel -->
    <div class="form-item-group support-channel">
      <el-form-item
        label="Support Channel"
        prop="SupportChannelType"
      >
        <el-select
          :disabled="disabledItem"
          v-model="formData.SupportChannelType"
          placeholder="Support Channel"
          @change="resetValue('SupportChannelText')"
        >
          <el-option
            v-for="item in supportChannelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!-- level 2 SupportChannelType -->
      <el-form-item
        v-if="formData.SupportChannelType === 'Discord'"
        prop="SupportChannelText"
      >
        <el-input
          :disabled="disabledItem"
          v-model="formData.SupportChannelText"
          style="min-width:300px"
          placeholder="Discord ID / Discord Ticket Number"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="formData.SupportChannelType === 'Email'"
        prop="SupportChannelText"
      >
        <el-input
          :disabled="disabledItem"
          v-model="formData.SupportChannelText"
          placeholder="Email Address"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="formData.SupportChannelType === 'Other'"
        prop="SupportChannelText"
      >
        <el-input
          :disabled="disabledItem"
          v-model="formData.SupportChannelText"
          placeholder="Others"
          clearable
        ></el-input>
      </el-form-item>
    </div>

    <!-- Purchasing Channel -->
    <div class="form-item-group purchasing-channel">
      <el-form-item  label="Purchasing Channel" prop="BuyChannelType">
        <el-select
          :disabled="disabledItem"
          v-model="formData.BuyChannelType"
          placeholder="Purchasing Channel"
          @change="resetValue('OrderNo')"
        >
          <el-option
            v-for="item in purchasingChannelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!-- level 2 BuyChannelType -->

      <template v-if="formData.BuyChannelType.length">
        <el-form-item
          prop="OrderNo"
           >
        <el-input
          :disabled="disabledItem"
          v-model="formData.OrderNo"
          placeholder="Order Number"
          id="order-num-input"
          clearable
        >
        <i class="el-icon-info"></i>
        </el-input>
      </el-form-item>
      
        <el-form-item prop="BuyScreenShot" class="order-image-url">
          <el-input
          :disabled="disabledItem"
          clearable
            v-model="formData.BuyScreenShot"
            placeholder="Order image url"
          >
            <!-- <i slot="append" class="el-icon-picture-outline" ></i> -->
            <i slot="prepend" class="el-icon-picture-outline"></i>
          </el-input>
           <el-tooltip popper-class="form-tip-popper" placement="right" class="form-input-tip-icon" effect="dark">
              <i class="el-icon-info"  ></i>
              <div slot="content">Please upload your picture to an image hosting website (e.g. <a href="https://imgbb.com/" target="_blank" style="text-decoration:underline;color:#00946c">imgbb</a>), copy the generated URL, and paste it here.</div>
            </el-tooltip>
        </el-form-item>
        
      </template>
    </div>

    <el-divider content-position="left"><h3>Product Issue</h3></el-divider>
    <!-- Fault Type -->
    <div class="form-item-group fault-type">
      <el-form-item label="Defective Part" prop="FaultType">
        <el-select  
          :disabled="disabledItem" 
          v-model="formData.FaultType" 
          placeholder="Defective Part"
          >
          <el-option
            v-for="item in faultTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!-- level 3 FaultType -->
      <el-form-item
        v-if="formData.FaultType === 'Accessories'"
        prop="FaultAccessoryType"
      >
        <el-select
          :disabled="disabledItem" 
          v-model="formData.FaultAccessoryType"
          placeholder="Choose accessories"
        >
          <el-option
            v-for="item in faultAccessOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="formData.FaultType === 'Other'" prop="FaultOtherText">
        <el-input
          :disabled="disabledItem" 
          v-model="formData.FaultOtherText"
          clearable
          placeholder="Please indicate"
        ></el-input>
      </el-form-item>
    </div>

    <!-- Issue Description -->
    <div class="form-item-group issue-desc">
      <el-form-item label="Issue Description" prop="ProblemDescription">
        <el-input
          :disabled="disabledItem" 
          show-word-limit
          type="textarea"
          v-model="formData.ProblemDescription"
          clearable
          placeholder="Please illustrate the device issue and the communication porcess with tech support in detail, so that our Support Team can approve your tech support ticket effectively."
        >
        </el-input>
      </el-form-item>
    </div>

    <!-- Issue images -->

    <div class="form-item-group issue-images">
         
      <div
        class="picture-input-item"
        v-for="(item, index) in formData.Picture"
        :key="index"
      >
        <el-form-item 
            :label="index === 0 ? 'Issue images' : ' '" 
            :prop="'Picture.'+index+'.PictureUrl'"
            :rules="formRules.Picture"
        >
          <el-input
            :disabled="disabledItem" 
            v-model="item.PictureUrl"
            placeholder="image url"
            clearable
          >
            <i slot="prepend" class="el-icon-picture-outline"></i>
            <i
              slot="append"
              style="cursor: pointer; color: #ff0000"
              class="el-icon-delete"
              v-if="!disabledItem"
              @click="delIssueImage(index)"
            ></i>
          </el-input>
            <el-tooltip 
              popper-class="form-tip-popper" 
              placement="right" 
              class="form-input-tip-icon" 
              effect="dark" 
            >
              <i class="el-icon-info"  ></i>
               <div slot="content">Please upload your picture to an image hosting website (e.g. <a href="https://imgbb.com/" target="_blank" style="text-decoration:underline;color:#00946c">imgbb</a>), copy the generated URL, and paste it here.</div>
            </el-tooltip>
        </el-form-item>
        
      </div>
        <el-button
          type="primary"
          class="add-issue-image"
          v-if="formData.Picture.length < 5 && !disabledItem"
          @click="addIssueImage"
          round
          plain 
          size="mini"
          icon="el-icon-plus"
        ></el-button>
    </div>
       


    
    
    <!-- 收件人信息 -->
    <el-divider content-position="left"><h3>Return Address</h3></el-divider>
    <div class="form-item-group shipping-info">
      <el-form-item prop="RecMan" label="Name" class="full-width">
        <el-input   v-model="formData.RecMan" placeholder="Name" clearable ></el-input>
      </el-form-item>

      <el-form-item prop="RecPhone" label="Tel" class="full-width">
        <el-input v-model="formData.RecPhone" placeholder="Tel" clearable ></el-input>
      </el-form-item>


      <el-form-item prop="RecEmail" label="Email" class="full-width">
        <el-input v-model="formData.RecEmail" placeholder="Email" clearable ></el-input>
      </el-form-item>


      <el-form-item prop="RecCountryCode" label="Country" class="full-width">
        <el-select  v-model="formData.RecCountryCode" filterable placeholder="Country" @change="resetValue('RecState') ">
          <el-option
            v-for="item in countryOptions"
            :key="item.name"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
        <el-tooltip popper-class="form-tip-popper" placement="right" class="form-input-tip-icon" effect="dark"  content="Please note that we are not able to ship to country or region that is not included in this list." >
          <i class="el-icon-info"  ></i>
        </el-tooltip>
      </el-form-item>



      <el-form-item prop="RecState" label="State" class="full-width">
        <el-select 
          
          v-if="countryOptions[formData.RecCountryCode] && countryOptions[formData.RecCountryCode]['regions']"  
          v-model="formData.RecState" 
          filterable 
          placeholder="State"
        >
          <el-option
            v-for="item in countryOptions[formData.RecCountryCode]['regions']"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <el-input  v-else  v-model="formData.RecState"  placeholder="State" clearable class="full-width"></el-input>
      </el-form-item>

       <el-form-item prop="RecCity" label="City" class="full-width">
        <el-input  v-model="formData.RecCity"  placeholder="City" clearable ></el-input>
      </el-form-item>

     

      <el-form-item prop="RecAddr" label="Street" class="full-width">
        <el-input  v-model="formData.RecAddr"  placeholder="Street" clearable ></el-input>
      </el-form-item>


      <el-form-item prop="RecPostCode" label="Postcode" class="full-width">
        <el-input  v-model="formData.RecPostCode"  placeholder="Postcode" clearable ></el-input>
      </el-form-item>


      <el-form-item prop="RecCompany" label="Company" class="full-width" v-if="false">
        <el-input  v-model="formData.RecCompany"  placeholder="Company" clearable ></el-input>
      </el-form-item>
    </div>

    <!-- DHL -->
       
    <template v-if="visibleDHLEmail">
        <el-divider content-position="left">
          <h3>DHL Account Approvement</h3>
        </el-divider>
        <div class="form-item-group">
            <el-alert 
              class="form-item-tip tracking-notice"
              type="success"
              effect="light"
              :center="false"
              :closable="false"
              v-show="visibleDhlEmailNote"
            >
            <div slot="title">
                <p>Please register an account on <a href="https://mydhlplus.dhl.com/cn/en/home.html#/getQuoteTab" target="_blank">DHL</a> with <b>Seeed’s DHL account: 952360366</b> and return the package to our warehouse following the below guide:</p>
                <p><i class="el-icon-document"></i><a target="_blank" href="https://docs.google.com/document/d/1D-2Nh77rxB1Xl4VXTllg5BR5X-UEnKfm/edit?usp=sharing&ouid=108441619004956477905&rtpof=true&sd=true">SenseCAP M1 Return Guide on Free DHL Delivery</a></p>
                <p>After you successfully register and activate the DHL account, please enter your DHL account (Email) below and submit for approval. </p>
            </div>
        </el-alert>
           <el-form-item  v-if="visibleDHLEmail"  label="DHL registered email" prop="DHLEmail" class="full-width">
              <el-input :disabled="disabledDHLEmail"  v-model="formData.DHLEmail" placeholder="DHL registered email" clearable ></el-input>
          </el-form-item>
           <div class="form-item-tip"  v-if="IS_DHL_APPROVED" style="margin-top:5px;font-weight:bold;color:#00946c;font-size:14px;"> <i class="el-icon-success" style="margin-right:5px"></i> Your account has been approved</div>
        </div>
    </template>

  <!-- Send Tracking number -->
    <template v-if="visibleDHLNum">
        <el-divider content-position="left" ><h3>Shipment Information</h3></el-divider>
       
        <div class="form-item-group tracking-number" >
           <el-alert 
              class="form-item-tip tracking-notice"
              type="success"
              effect="light"
              :center="false"
              :closable="false"
              v-show="visibleTrackingNote"
            >
            <div slot="title" v-if="IS_SEEED_PAYEE">
              <p>You will receive an email from <a href="https://mydhlplus.dhl.com/cn/en/home.html#/getQuoteTab" target="_blank">DHL</a> when your account has been approved, and you could log in to <a href="https://mydhlplus.dhl.com/cn/en/home.html#/getQuoteTab" target="_blank">DHL</a> and create a shipment following the same guide.</p>
              <p>Please submit the tracking number below when you complete the shipment process:</p>
            </div>

            <div slot="title" v-else>
                <p>Please return the package to us using the information provided in <i class="el-icon-document"></i><a target="_blank" href="https://docs.google.com/document/d/1D-2Nh77rxB1Xl4VXTllg5BR5X-UEnKfm/edit?usp=sharing&ouid=108441619004956477905&rtpof=true&sd=true">SenseCAP M1 Return Information </a> and submit the tracking number below when you complete the shipment process:</p>
            </div>
        </el-alert>
          <el-form-item :label="IS_SEEED_PAYEE ? 'Tracking number (DHL)' : 'Tracking number (DHL/FedEx)' " prop="SendTrackingNo" class="full-width" >
              <el-input ref="trackingNumber" :disabled="disabledDHLNum" v-model="formData.SendTrackingNo" placeholder="Tracking number" clearable ></el-input>
          </el-form-item>

           <el-alert 
              class="form-item-tip tracking-notice"
              type="info"
              effect="light"
              :center="false"
              :closable="false"
              v-if="IS_SEEED_PAYEE"
            >
            <div slot="title" >
                <h2 style="color:#000;font-size:16px;margin:8px 0 8px 0"> Product Return Guide</h2>
                <p><i class="el-icon-document"></i><a target="_blank" href="https://docs.google.com/document/d/1D-2Nh77rxB1Xl4VXTllg5BR5X-UEnKfm/edit?usp=sharing&amp;ouid=108441619004956477905&amp;rtpof=true&amp;sd=true">SenseCAP M1 Return Guide on Free DHL Delivery</a></p>
                <p> Note: Please make sure it is a hardware defect and get a consensus with tech support that the fee will be bound by Seeed before using this guide to return the device.  </p>
            </div>
          </el-alert>
        </div>


        
    </template>

    


    <!-- REC Tracking -->
       
    <template v-if="visibleRecTracking">
        <el-divider content-position="left"><h3>Receive tracking info</h3></el-divider>
        <div class="form-item-group">
           <el-form-item label="Tracking number" class="full-width">
              <el-input readonly="true"  v-model="formData.RecTrackingNo"  ></el-input>
          </el-form-item>
          <el-form-item label="Carrier name" class="full-width" >
              <el-input readonly="true" v-model="formData.CarrierName" ></el-input>
          </el-form-item>
        </div>
    </template>
    
    <template v-if="!disabledForm">
      <el-divider></el-divider>
      <div class="form-item-group" style="margin-top:40px;text-align:center;padding-bottom:30px ;">
        <el-button  style="width:20%"  :type="'success'" @click="submitForm('supportForm')">{{submitText}}</el-button>
        <el-button v-if="visibleSave" style="width:20%" type="warning" @click="saveForm('supportForm')">Save</el-button>
      </div>
    </template>
  </el-form>

  <el-dialog 
     :visible.sync="visibleTrackingAlert"
     class="submit-alert"
     >
     <div class="submit-notice-content" >
       <div class="result-tip">Tracking number has been successfully submitted!</div>
       <p>You have completed all required steps in this ticket, and you could come back to check your ticket status.</p>
     </div>
      <el-button @click="checkTicketStatus" type="success" style="width:100%;margin-top:10px">Check Ticket Status</el-button>
  </el-dialog>

 </div>
</template>
<script>
import _rules from "./rules";
import formOptions from "./options";
import {$msg} from '@/store/';
import { mapGetters , mapActions, mapState, mapMutations} from "vuex";
import {
    CREATE_WORK_ORDER,
    SAVE_WORK_ORDER,
    UPDATE_WORK_ORDER,
    SUBMIT_WORK_ORDER,
    MSG_A,
    MSG_B,
    MSG_C,
    MSG_D0,
    MSG_D1,
    MSG_D2,
    MSG_D3,
    } from "@/store/index";

export default {
  components: {},
  data() {
    return {
      formData:'',
      ...formOptions,
      updateLimitKeys : [
        'RecCompany',
        'RecCountryCode',
        'RecState',
        'RecCity',
        'RecAddr',
        'RecPostCode',
        'RecMan',
        'RecPhone',
        'RecEmail',
        'DHLEmail',
        'SendTrackingNo'
      ],
      saveValidateKeys:[
        'SNCode'
      ],
      visibleDHLAlert : false,
      visibleTrackingAlert :false,
      haveRegDhlEmail :false,
      hasShipOut : false,
    };
  },


  computed:{
       ...mapGetters([
          "FORM_DATA",
          'MESSAGE_CODE',
          'IS_NEED_RETURN',
          'IS_SEEED_PAYEE',
          'VISIBLE_TRACKING_ALERT',
          'IS_DHL_APPROVED'
        ]),

        // 提交按钮标题
        submitText(){
          switch(this.MESSAGE_CODE){
            case MSG_A : return 'Submit';
            case MSG_B : 
            case MSG_C : 
            case MSG_D0 : 
            case MSG_D1:  
            case MSG_D2 : 
            case MSG_D3 : return 'Submit'; 
            default : return 'Submit';
          }
        },

        // 暂存按钮
        visibleSave(){return  this.MESSAGE_CODE === MSG_A || this.MESSAGE_CODE === MSG_B },

        // DHL运单号输入框
        visibleDHLNum(){return this.IS_NEED_RETURN && (this.MESSAGE_CODE === MSG_D1 || this.MESSAGE_CODE === MSG_D2 || this.MESSAGE_CODE === MSG_D3 )},

        // DHL邮箱
        visibleDHLEmail(){return this.IS_NEED_RETURN && this.IS_SEEED_PAYEE },


        // Rec tracking
        visibleRecTracking(){return this.MESSAGE_CODE === MSG_D3 && this.formData.RecTrackingNo},

        // DHL运单号
        disabledDHLNum(){ return this.MESSAGE_CODE === MSG_D2 || this.MESSAGE_CODE === MSG_D3},


        // DHL 邮箱
        disabledDHLEmail(){
           return this.MESSAGE_CODE === MSG_D1 || this.MESSAGE_CODE === MSG_D2 || this.MESSAGE_CODE === MSG_D3
        },
        

        // 表单项目的disable控制
        disabledItem(){return !this.MESSAGE_CODE || (this.MESSAGE_CODE && this.MESSAGE_CODE !== MSG_A && this.MESSAGE_CODE !== MSG_B && this.MESSAGE_CODE !== MSG_C)},


        // 所有表单项 disabled
        disabledForm(){return !this.MESSAGE_CODE || this.MESSAGE_CODE === MSG_D3},

        // 根据当前状态 生成新的校验规则
       
        formRules (){
          let self = this ;
            let msgCode = this.MESSAGE_CODE;
            let rules = {..._rules};

            // Support Channel校验
            if( rules.SupportChannelText && rules.SupportChannelText.length){
              
              if(self.formData.SupportChannelType !== 'Email'){
                rules.SupportChannelText = rules.SupportChannelText.filter(item => item.type !== 'email');
              }

              // Support Channel Discord 校验
              if(self.formData.SupportChannelType !== 'Discord'){
                rules.SupportChannelText = rules.SupportChannelText.filter(item => {
                  if(item.required){item.message="This is required field."}
                  return item.validator !== 'validateSHGD'
                })
              }else{
                rules.SupportChannelText = rules.SupportChannelText.map(item => {
                  if(item.required){item.message="Your Discord ID and Discord Ticket Number (if any)"}
                  return item 
                })
              }
              
            }

            
              

            // 订单号特殊校验
            if(self.formData.BuyChannelType !== 'Seeed Online Store' && rules.OrderNo && rules.OrderNo.length){
              rules.OrderNo = rules.OrderNo[0];
            }
            // tracking number 特殊校验
            if(!self.IS_SEEED_PAYEE && rules.SendTrackingNo && rules.SendTrackingNo.length){
              rules.SendTrackingNo = rules.SendTrackingNo[0]
            }


            if(!msgCode || msgCode === MSG_A || msgCode === MSG_B ||  msgCode === MSG_C){return rules}
            if(msgCode === MSG_D3){return {}}
            let obj = {};
            for(let key of this.updateLimitKeys){
              if(rules[key]){obj[key] = rules[key] }
            }
            return obj
        },

        visibleDhlEmailNote(){
          return this.MESSAGE_CODE === MSG_A || this.MESSAGE_CODE === MSG_B
        },

        visibleTrackingNote(){
          return !this.disabledDHLNum
        },

  },

  watch:{
    FORM_DATA:{
      immediate : true,
      handler(data){
        this.formData = data
      }
    },
    formRules(){setTimeout(()=>{this.$refs['supportForm'].clearValidate()})},
    VISIBLE_TRACKING_ALERT(val){
      this.visibleTrackingAlert = val;
    }
  },

  
  methods: {
    ...mapActions([
        'getWorkOrderData',
        'updateMessageCode',
        'operateWorkOrder',
        'updateMsgCode'
    ]),
    ...mapMutations(['setVisibleTicketStatusPanel']),
    
    checkTicketStatus(){
      let self = this ;
      self.visibleTrackingAlert = false;
      self.setVisibleTicketStatusPanel(true);
    },

    /**
     * 增加一张问题描述图片
    */
    addIssueImage() {
      this.formData.Picture.push({
        PictureUrl: "",
      });
    },

    /**
     * 删除一张问题描述图片
    */
    delIssueImage(index) {
      this.formData.Picture.splice(index,1);
      if(!this.formData.Picture.length){
          this.addIssueImage();
      }
    },

    /**
     * 重置某一个字段值
    */
    resetValue(key) {
      if (!(key in this.formData)) return;
      this.formData[key] = "";
    },


    /**
     *  提交
    */
    submitForm(formName,is) {
      let self = this ;

      self.updateLocalStorage();
      self.$refs[formName].validate(async (valid) => {
          if (!valid) { 
            self.visibleDHLAlert = false;
            $msg('error','Validate failed');
            self.locationError();
            return;
          };
          self.requestSubmit();
        });
    },

   async requestSubmit(){
      let self = this ;
       let isUpdateMsgCode = await self.updateMsgCode('updateMsgCode');
        let msgCode = self.MESSAGE_CODE;
        switch(msgCode){
            case MSG_A : self.setOperateQue([CREATE_WORK_ORDER,SUBMIT_WORK_ORDER],'create2submit');break;
            case MSG_B : self.setOperateQue([SAVE_WORK_ORDER,SUBMIT_WORK_ORDER],'save2submit');break;
            case MSG_C : self.setOperateQue([SAVE_WORK_ORDER],'save=submit');break;
            case MSG_D0 : 
            case MSG_D1 : 
            case MSG_D2 :
            case MSG_D3 : self.setOperateQue([UPDATE_WORK_ORDER],'update');break;
        }
    },


    /**
     *  暂存
    */
     saveForm(formName){
        let self = this ;
        self.updateLocalStorage();
         self.$refs[formName].validateField(self.saveValidateKeys,async (errorMsg,errFields) => {
            if (errFields) {
              $msg('error','Validate failed');
              self.locationError();
              return;
            };
            let isUpdateMsgCode = await self.updateMsgCode('updateMsgCode');
            let msgCode = self.MESSAGE_CODE;
            msgCode === MSG_A ? 
              self.setOperateQue([CREATE_WORK_ORDER],'create=save'):
              self.setOperateQue([SAVE_WORK_ORDER],'save');
          });
    },
  
    // 定位到未通过校验的表单项
    locationError(){
        let self = this ;
        setTimeout(()=>{ self.toEle('.el-form-item.is-error');  })
    },
    
    // 锚点定位到某个元素
    toEle(ele){
      if(!ele) return;
      try{
          let $ele = document.body.querySelector(ele);
          if(!$ele) return;
          document.documentElement.scrollTop =$ele.offsetParent.offsetTop + $ele.offsetTop - 140  ;
        }catch(err){
          console.log(err)
        }
    },

    /**
     *  设置表单操作依次请求队列 
    */
    async setOperateQue(queNames,operaType){
      let isSuccess = false ;
      if(!queNames) return;
      let index = 0 , len = queNames.length;
      for(let name of queNames){
        let data = this.filterFormData(name);
        isSuccess = await this.operateWorkOrder({
          name ,
          formData : data,
          operaType
        })
        if(!isSuccess) {this.getWorkOrderData();return};
        index += 1;
        if(index === len){this.getWorkOrderData();}
      }
    },

    /**  
     * 过滤提交的表单字段 
     * 更新工单
    */
    filterFormData(operate){
      if(operate !== UPDATE_WORK_ORDER) return this.formData;
      let obj = {};
      for(let key of this.updateLimitKeys){if(key in this.formData){obj[key] = this.formData[key];}}
      return obj;
    },

    /** 
     * 将formdata存储localstorage
    */
    updateLocalStorage(){
      localStorage.setItem('formData',JSON.stringify(this.formData));
    },

    /* tracking field location */
    locationTip(){
      let self = this ;
        if(this.visibleDHLNum && !this.formData.SendTrackingNo){
          setTimeout(()=>{ 
            self.toEle('.tracking-number');
            self.$refs['trackingNumber'] &&  self.$refs['trackingNumber'].focus();
          },100)
        }
    }

  },
  updated(){
    this.locationTip();
  },
  mounted(){
    this.locationTip();
  }
};
</script>
<style lang="scss">
#ticketNumber{
    color: #000;
    font-size: 18px;
    font-weight: bold;
    padding: 0 40px;
    span{
      color:#00946c ;
      font-size: 18px;
      font-weight: bold;
      letter-spacing:.4px;
    }
  }
</style>

