<template lang="">
  <el-dialog  
    :visible="true"
    :show-close="false"
    fullscreen
    class="verify-sn-alert"
    >

    <div class="logo"><img src="/img/logo.png" alt=""></div>
    <el-form 
      ref="$verify-form"
      :model="formData"
      label-position="left"
      label-width="130"
      class="verify-sn-form"
    >
     <h3 type="primamry">Verify  Serial Number (SN)</h3>
      <el-form-item >
        <el-input v-model="formData.snNum" placeholder='Please verify your device Serial Number(SN) to enter the aftersale ticket'></el-input>
      </el-form-item>
      <el-button 
        :type="formData.snNum.length === 0 ? 'info' : 'primary' " 
        :disabled='formData.snNum.length === 0' 
        style="width:100%" 
        @click="submitSn">Verify</el-button>
         <p class="work-order-num" v-if="FORM_DATA.WorkOrderNo">Aftersale Ticket Number : <span>{{FORM_DATA.WorkOrderNo}}</span> </p>
    </el-form>
   
    <el-button style="display:block;margin:20px auto" v-if="this.FORM_DATA.SNCode && IS_DEV" @click="function(){this.formData.snNum = this.FORM_DATA.SNCode}.bind(this)">自动填充当前SN，仅方便测试</el-button>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data(){
    return { 
      formData:{
        snNum : ''
      }
    }
    
  },

  computed: {
    ...mapGetters([
      "FORM_DATA",
      'MESSAGE_CODE',
      'IS_DEV'
    ]),
  },
  
  methods:{
    ...mapActions(['verifySn']),
    submitSn(){
      this.verifySn(this.formData.snNum);
    }
  }
}
</script>
<style lang="scss">
  h3{
    color: #01e2b1;
  }
  .work-order-num{
    color: #fff;
    text-align: right;
    span{
      color:#01e2b1 ;
      font-weight: bold;
    }
  }
  .verify-sn-alert{
   
  }
  .verify-sn-form{
    max-width: 500px;
    padding: 20px 20px;
    border-radius: 5px;
    margin: 0 auto;
    text-align: center;
    background-color: #000;
  }
</style>