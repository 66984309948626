import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
// import router from './router'
import store from './store'
import Element from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/element-variables.css' // 自定义主题
Vue.use(Element)
Vue.config.productionTip = false;
new Vue({
  // router,
  store,
  render: h => h(App)
}).$mount('#app')
