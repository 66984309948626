<template>
<div class="fae-form-container" v-show="visibleFAE">
 <h2 ></h2>
  <el-form
    ref="$faeForm"
    :model="formData"
    label-position="left"
    id="fae-form"
    class="m1-form"
    inline
    v-if="visibleFAE"
    :rules="formRules"
  >
    <div class="form-item-group">
        <el-form-item style="max-width:100%" label="FAE Notes" class="full-width" prop="FAERemark">
            <el-input type="textarea" v-model="formData.FAERemark" placeholder="FAE Notes"></el-input>
        </el-form-item>
        <el-form-item style="max-width:100%;" label="FAE Verify Code" class="full-width" prop="VerifyCode">
            <el-input v-model="formData.VerifyCode" placeholder="Verify Code" clearable ></el-input>
        </el-form-item>
        <div class="fae-submit-box">
            <el-button type="success" @click="submit('$faeForm','A')" id="fae-submit">FAE Submit</el-button>
            <!-- <el-button type="warning" @click="submit('$faeForm','B')" id="fae-reject">FAE Reject</el-button> -->
        </div> 
    </div>
  </el-form>
</div>
</template>

<script>
import { mapGetters , mapActions, mapState} from "vuex";
import Api from "@/api";
import {
    MSG_C,
} from "@/store/index";

export default {
    data(){
        return {
            formData:{
                FAERemark: "",  // FAE备注
                VerifyCode : '' // 验证码
            },
            formRules:{
                FAERemark:[{type:'string',max:2000 , message:"This field cannot be longer than 2000 characters"}],
                VerifyCode:[{ required: true, message: "This is required field" }]
            }
        }
    },
    computed:{
        ...mapGetters([
          "FORM_DATA",
          'MESSAGE_CODE',
        ]),

        visibleFAE(){
            return this.MESSAGE_CODE === MSG_C
        }
    },
    FORM_DATA:{
      immediate : true,
      handler(data){
          console.log(data)
        this.formData.FAERemark = data.FAERemark
      }
    },
    methods:{
        ...mapActions([
            'submitFAEForm',
        ]),
        submit(formName,FAEStatus){
            let self = this ;
            self.$refs[formName].validate((valid) => {
                if (!valid) return;
                this.formData['FAEStatus'] = FAEStatus;
                this.submitFAEForm(this.formData);
            })
        }
    }
}
</script>
<style lang="scss">
.fae-form-container>h2{
    width: 100%;
    box-sizing: border-box;
    position: relative;
    text-align: center;
    max-width: 1200px;
    margin: 50px auto 20px auto;
    &:after{
        position: relative;
        content:'The following information will be filled in by tech support to verify this support ticket';
        display: inline-block;
        width: auto;
        background: rgba(249,249,249,1);
        padding: 10px 10px;
        z-index: 10;
        font-size: 20px;
        color: #000;
        @media screen and (max-width:1080px) {
            font-size: 15px;
        }
    }
    &:before{
        content:'';
        height: 2px;
        width: 100%;
        background: #555;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1;
    }
    
}
</style>
