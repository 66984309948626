export default [
    {
      key : 'A',
      title : 'Support Ticket Created',
      desc : `Thank you for your submition. Your support ticket has been created. <br/>Please wait for the approval.`
    },{
      key : 'B',
      title : 'Support Ticket Approved',
      desc : 'Your support ticket has been approved. You need to submit the tracking number after shipping back the item.'
    },{
      key : 'C',
      title : 'DHL Account Approved',
      desc : ''
    },{
      key : 'D',
      title : 'Return Tracking Number Submitted',
      desc : 'Thank you for submitting the tracking number. You have completed all required steps in this ticket.',
      warning : 'Please submit the return tracking number to complete the aftersale ticket submission.',
      note : 'Please note that there will be a 1-2 days delay for status changed to “Package Handled by Seeed” when the DHL page shows “Delivered”, since there will be a quality inspection process.'
    },{
      key : 'F',
      include : ['E','F'],
      title : 'Package Handled by Seeed',
      desc : 'Your package has been handled well by Seeed. Please be patient for the repair/replace process.'
    }, {
      key : 'G',
      title : 'Product Sent Out From Seeed',
      desc : `Product has been sent out.`
    },{
      key : 'H',
      title : 'Product Received by User',
      desc : 'Your package has been delivered.'
    },{
      key : 'I',
      title : 'Support Ticket Completed',
      desc : ''
    }
   /*  {
      key : 'E',
      title : 'Package Handled by Seeed',
      desc : 'Your package has been handled well by Seeed. Please be patient for the repair/replace process.'
     }, */
]

