/*
 * @Author: your name
 * @Date: 2021-09-17 22:45:59
 * @LastEditTime: 2021-09-17 23:31:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /support-form/src/components/supportForm/rules.js
 */

import extraValidate from "./validate";

let { validateImg ,validateSHGD,validateSN , validateSeeedOrder,validateDhl} = extraValidate;

export default {
  SNCode: [
    { required: true, type:'string', whitespace:true,message: "This is required field" },
    { validator: validateSN, trigger: "blur" }
  ],
  /* 支持渠道 */
  SupportChannelType: [{ required: true, message: "This is required field" }],
  SupportChannelText: [
    { required: true, type:'string', whitespace:true,message: "This is required field" },
    {
      type: "string",
      max: 200,
      message: "This field cannot be longer than 200 characters",
    },
    { type: "email", message: "It is not a valid email", trigger: "blur" },
    {  validator: validateSHGD, trigger: "blur"}
],

  /* 购买渠道 */
  BuyChannelType: [{ required: true, message: "This is required field" }],
  OrderNo: [
    { required: true,type:'string', whitespace:true, message: "This is required field" },
    { validator: validateSeeedOrder, trigger: "blur"}
  ],
  BuyScreenShot: [
    { type:'string',required: true, message: "This is required field" },
    { type: "url", message: "This is not a valid url", trigger: "blur" },
    {
      type: "string",
      max: 2000,
      message: "This field cannot be longer than 2000 characters",
    }
    // { validator: validateImg, trigger: "blur" },
  ],

  /* 问题描述 */
  FaultType: [{ required: true, message: "This is required field" }],
  FaultAccessoryType: [{ required: true, message: "This is required field" }],
  FaultOtherText: [{ required: true,type:'string', whitespace:true, message: "This is required field" },
  {
    type: "string",
    max: 200,
    message: "This field cannot be longer than 200 characters",
  }],
  ProblemDescription: [
    { required: true,type:'string', whitespace:true, message: "This is required field" },
    {
      type: "string",
      max: 2000,
      message: "This field cannot be longer than 2000 characters",
    },
    {
      type: "string",
      min: 100,
      message: "This field cannot be less than 100 characters",
    }
  ],

  /* 售后类型 */
  AfterSaleType: [{ required: true, message: "This is required field" }],
  IsReplaceReturn: [{ required: true, message: "This is required field" }],
  ReturnPayer: [{ required: true, message: "This is required field" }],
  SendAccessory: [{ required: true, message: "This is required field" }],
  AntennaModel: [{ required: true, message: "This is required field" }],
  OtherAccessory: [
    { required: true,type:'string', whitespace:true,message: "This is required field" },
    {
      type: "string",
      max: 200,
      message: "This field cannot be longer than 200 characters",
    }
  ],
  Picture: [
  { type: "url", message: "This is not a valid url", trigger: "blur" },
  // { validator: validateImg, trigger: "blur" },
  {
    type:'string',
    max: 2000,
    message: "This field cannot be longer than 2000 characters",
  }],

  /* 收件信息 */

  // 收件人
  RecMan: [
    { type:'string',whitespace:true,required: true, message: "This is required field" },
    {
      type: "string",
      max: 100,
      message: "This field cannot be longer than 100 characters",
    }
  ], 

  // 国
  RecCountryCode: [{ type:'string',whitespace:true,required: true, message: "This is required field" }], // 国家

   // 省
  RecState: [
    { type:'string',required: true, whitespace:true,message: "This is required field" },
    {
      type: "string",
      max: 150,
      message: "This field cannot be longer than 150 characters",
    }
  ],

  // 城市
  RecCity: [
    { type:'string',required: true,whitespace:true, message: "This is required field" },
    {
      type: "string",
      max: 150,
      message: "This field cannot be longer than 150 characters",
    }
  ], 


   // 公司
  RecCompany: [
    // { required: true, message: "This is required field" },
    {
      type: "string",
      max: 150,
      message: "This field cannot be longer than 150 characters",
    }
  ],

   // 地址详情
  RecAddr: [
    { type:'string',required: true, whitespace:true,message: "This is required field" }, {
      type: "string",
      max: 255,
      message: "This field cannot be longer than 255 characters",
    }
  ],

  // 邮编
  RecPostCode: [
    { required: true,whitespace:true, message: "This is required field" },
    {
      type: "string",
      max: 100,
      message: "This field cannot be longer than 100 characters",
    }
  ], 

  // 电话
  RecPhone: [
    { type:'string',required: true, whitespace:true,message: "This is required field" },
    {
      type: "string",
      max: 50,
      message: "This field cannot be longer than 100 characters",
    }
  ], 

  // Email
  RecEmail: [
    { type:'string',required: true, whitespace:true,message: "This is required field" },
    { type: "email", message: "It is not a valid email", trigger: "blur" },
    {
      type: "string",
      max: 50,
      message: "This field cannot be longer than 50 characters",
    }
  ], 
// DHL邮箱
  DHLEmail: [
    { type:'string',required: true, whitespace:true,message: "This is required field" },
    { type: "email", message: "It is not a valid email", trigger: "blur" },
  ], 

 // 寄件单号
  SendTrackingNo: [
    { type:'string',required: true,whitespace:true, message: "This is required field" },
    {
      type: "string",
      max: 100,
      message: "This field cannot be longer than 100 characters",
    },
    { validator: validateDhl, trigger: "blur" },
  ],
};
