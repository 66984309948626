<template lang="">
<el-dialog 
    custom-class="ticket-status-panel"
    fullscreen
    :visible.sync="VISIBLE_TICKET_STATUS_PANEL"
    top="0"
    :show-close="false"
    :close-on-press-escap="false"
    :lock-scroll="true"
>
  <div class="ticket-status-container">
    <div class="ticket-information">
      <h2 style="text-align:center;margin-bottom:30px;color:#000;">Ticket Information</h2>
      <ticket-info></ticket-info>
    </div>
    <div class="ticket-steps-container">
        <h2 style="text-align:center;margin-bottom:30px;color:#000;">Ticket Status</h2>
         <el-alert
          title="Note"
          description="The ticket status is updated every 5 mins. If you find there is delay, please check later."
          type="success"
          :closable="false"
          effect="light"
          :show-icon="false">
        </el-alert>
        <el-steps 
          finish-status="success" 
          process-status="process" 
          class="ticket-steps"
          direction="vertical"
          >
          <el-step v-for="(item,index) in status"  :key="item.key" :status="getStepType(item,index) ">
              <div class="ticket-status-title" slot="title">{{item['title']}}</div>
              <div slot="description">
                <div class="ticket-status-desc" 
                      v-if="index <= activeIndex"   
                      v-html="item['desc']">
                </div>

                <div class="process-time-alert" 
                  v-if="(item.key === 'F' || item.key === 'E') && index === activeIndex"  
                  @click="alertTicketProcessTime">
                  Check Ticket Prorcessing Time
                </div>
               
                <div class="ticket-status-note" 
                  v-if="index === activeIndex && item['note']" 
                  v-html="item['note']">
                </div>

                <div 
                  class="ticket-status-warning" 
                  v-if="index === activeIndex+1 && item.key === 'D' && !HAS_SUBMIT_TRACKING && item['warning']" 
                  v-html="item['warning']">
                </div>

              </div>
            
              
          </el-step>
        </el-steps>
    </div>
  </div>

  <div class="show-ticket-form">
    <el-button class="show-ticket-form-btn" type="primary"  @click="closePanel">View Ticket Information Form</el-button>
  </div>
  

</el-dialog>
</template>
<script>
import TicketInfo from "@/components/ticketInfo";
import { mapGetters , mapMutations} from "vuex";
import {
    MSG_A,
    MSG_B,
    MSG_C,
    MSG_D0,
    MSG_D1,
    MSG_D2,
    MSG_D3,
    } from "@/store/index";
import allStatus from "./status"
export default {
  components:{TicketInfo},
  computed:{
    ...mapGetters([
      'ORDER_STATUS',
      'FORM_DATA',
      'TICKET_DATA',
      'IS_SEEED_PAYEE',
      'IS_NEED_RETURN',
      'IS_NEED_REPAIR',
      'MESSAGE_CODE',
      'VISIBLE_TICKET_STATUS_PANEL',
      'HAS_SUBMIT_TRACKING'
    ]),
    status(){
      let len = this.ORDER_STATUS.length ;
      let data = [...allStatus]
      
      if(!this.IS_NEED_RETURN){
        data = data.filter(item => {
          if(item.key === 'B'){
            if(this.FORM_DATA.AfterSaleType === 'Send Accessories'){
              item.desc = 'Your support ticket has been approved! Your replacement parts will be sent in 5 working days. '
            }else{
              item.desc = 'Your support ticket has been approved. Please be patient with the shipping process.'
            }
          }
          return item.key !=='C' && item.key !== 'D' && item.key !== 'E' && item.key !== 'F';
        } )
      }else{
        if(!this.IS_SEEED_PAYEE){
         data = data.filter(item => {
           return item.key !== 'C'
          } )
        }
      }
    /*   if(!this.IS_NEED_REPAIR){
       data = data.filter(item => {return item.key !== 'F'} )
      } */

      // DHL Account Approved
      if(this.IS_SEEED_PAYEE && this.TICKET_DATA.DHLEmail){
        let index = data.findIndex(item => item.key === 'C');
        if(index >= 0){
           data[index].desc = `Your account <b>${this.TICKET_DATA.DHLEmail}</b> has been approved to use the DHL account, please create the shipment on <a href="https://mydhlplus.dhl.com/cn/en/home.html#/getQuoteTab" target="_blank">DHL</a>`
        }
      }

      // Product Sent Out From Seeed
      if(this.FORM_DATA.RecTrackingNo){
        let index = data.findIndex(item => item.key === 'G');
        if(index >= 0 ){
          data[index].desc = `Product has been sent out.<br>
                    Your DHL tracking number is <b> ${this.FORM_DATA.RecTrackingNo} </b><br>
                    Please check <a href="https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${this.FORM_DATA.RecTrackingNo}" target="_blank">HERE</a> for the shipment update.</p>`
        }
      }

       // Return Tracking Number Submitted
       if(this.IS_SEEED_PAYEE && this.TICKET_DATA.SendTrackingNo){
          let index = data.findIndex(item => item.key === 'D');
          if(index >= 0){
              data[index].desc = `Thank you for submitting the tracking number.<br/>
                    You have completed all required steps in this ticket.<br/>
                    Please check your tracking number here <a target="_blank" href="https://www.dhl.com/cn-en/home/tracking/tracking-express.html?tracking-id=${this.TICKET_DATA.SendTrackingNo}">${this.TICKET_DATA.SendTrackingNo}</a>`
          }   
       }
        
      return data;
    },
    activeIndex(){
      let len = this.ORDER_STATUS.length ;
      if(len === 0 ) return -1;
      let last =  this.ORDER_STATUS[len-1];
      if(last === 'A' && (this.MESSAGE_CODE === MSG_A || this.MESSAGE_CODE === MSG_B)){
        return -1;
      }

      let acindex = 0;
      let p = (arr,len)=>{
        let last = arr[len -1 ];
        acindex = this.status.findIndex(item => item.key === last || (item.include?.length && item.include.indexOf(last) >= 0 )) ;
        if(acindex < 0){
          len -= 1;
          if(len > 0 ){
            p(arr,len)
          }else{
            return 0
          }
        }else{
         return acindex;
        }
      }

      try{
        p(this.ORDER_STATUS,len);
      }catch(err){
        acindex = this.status.findIndex(item => item.key === last);
      }
      return acindex;
    },
  },
  methods:{
    ...mapMutations(['setVisibleTicketStatusPanel']),
    closePanel(){
      let self = this ;
      this.setVisibleTicketStatusPanel(false);
    },

    getStepType(item,index){
      let activeIndex = this.activeIndex;
      let statusArr = this.status ;
      if(index <= activeIndex  ){
        return 'success';
        // return this.ORDER_STATUS.findIndex(i => i === item.key) >= 0 ?  'success' : 'process'
      }else if(index === activeIndex+1){
        return 'process'
      }else{
        return ''
      }
    },
    alertTicketProcessTime(){
      this.$msgbox({
        dangerouslyUseHTMLString: true ,
        showConfirmButton : false ,
        showCancelButton : true,
        cancelButtonText : 'Close',
        cancelButtonClass : "close-alert-btn",
        customClass:"ticket-process-time-alert",
        message :`<h2 >Ticket Processing Time</h2>
            <p >For repair cases, the repair is supposed to be completed within <b>20 working days</b> after we received the return package.</p>
            <p >For replacement cases, we will arrange the shipment in <b>5 working days</b> after we receive the returned defective device package.</p>
            <p >For replacement parts cases, we will arrange the shipment in <b>5 working days</b> after the ticket is completed. </p>`
      })
    }
  },
  created(){
     if(this.MESSAGE_CODE !== MSG_A && this.MESSAGE_CODE !== MSG_B){
       this.setVisibleTicketStatusPanel(true)
     }
  }
}
</script>
<style lang="scss">
.ticket-status-panel{
  background: #fff;
  .ticket-status-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    @media screen and(max-width:768px) {
      flex-wrap: wrap;

    }

  }
  .ticket-information{
    flex :1 0 0;
    padding: 0 20px ;
    @media screen and(max-width:768px) {
      flex :0 0 100%;
      order: 2;
      padding: 0 0 ;
      box-sizing: border-box;
      width: 100%;
    }
  }
  .ticket-steps-container{
    flex : 1 0 0 ;
    padding: 0 20px 20px 0;
    @media screen and(max-width:768px) {
      flex :0 0 100%;
      order: 1;
      box-sizing: border-box;
      padding: 0 0 ;
      width: 100%;
    }
    .ticket-steps{
      margin-top:20px;
    }
  }

  .el-step.is-vertical .el-step__main{
    padding: 0 15px 25px 15px;
    min-height: 80px;
  }
  .ticket-status-title{
    font-size:16px;
    font-weight: bold;
    padding-bottom: 8px;
  }
  .ticket-status-note{
    font-size: 13px;
    font-weight: bold;
    color: #333;
    background:#e6f4f0;
    padding: 8px 8px ;
    margin-top: 8px;
    word-break: break-word;
  }
  .ticket-status-warning{
    color:#f78c00;
    font-size: 13px;
    font-weight: bold;
    word-break: break-word;
  }
  .ticket-status-desc{
    font-size: 13px;
    line-height:18px;
    color: #000;
    word-break: break-word;
    
    a{
        color: #00946c;
        font-weight: bold;
        text-decoration: underline;
    }
  }

  .process-time-alert{
    color: #00946c;
    text-decoration: underline;
    font-weight: bold;
    margin-top: 6px;
    cursor: pointer;
    width: auto;
    display: inline-block
  }
    .el-step__head.is-process{
        color: #000;
        border-color: #000;
    }
    .el-step__title{
      &.is-process{
        color: #000;
        border-color: #000;
      }
    }
    .el-step__icon{
      
    }
    .el-step__head.is-success{
      .el-step__icon{
        background: #00946c;
        color: #fff;
      }
      .el-step__line{
         background: #00946c;
      }
      

    }
    .el-step__head.is-process{
      .el-step__icon{background-color: #fff;color: #000;border-color: #000;}
    }

   
  
}
  
  .ticket-process-time-alert {
    .close-alert-btn{
      &:hover{
        background:#fff;
        color:#000;
        border-color: #000;
      }
    }
    .el-message-box__message{
      h2{
            font-size: 18px;
            color: #000;
            font-weight: bold;
            text-align: center;
        }
        p{
            font-size: 14px;
            color: #000;
            padding: 0 0 0 0;
            line-height: 20px;
            margin: 0 0 10px 0 ;
            @media screen and (max-width:768px) {
                  line-height: 18px;
                  font-size: 12px;
                  margin: 0 0 5px 0 ;
            }
        }
  }
  }
    
    .show-ticket-form{
      width:100%;background:#fff;position:sticky;bottom:0;z-index:1000;text-align:center;
      .show-ticket-form-btn{
        width:50%;
        margin:20px 0 10px 0;
        border-color:transparent;
        background:#000;
        color:#01e2b1;
        &:hover{
          background:#000;
          color:#01e2b1;
        }
      }
    }
</style>