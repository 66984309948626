<template lang="">
  <div class="msg-code-control">
    
    <h5>当前操作码</h5>
    <el-radio-group v-model="msgCode" @change="updateMessageCode"> 
      <el-radio-button :label="MSG_A">A</el-radio-button>
      <el-radio-button :label="MSG_B">B</el-radio-button>
      <el-radio-button :label="MSG_C">C</el-radio-button>
      <el-radio-button :label="MSG_D0">D0</el-radio-button>
      <el-radio-button :label="MSG_D1">D1</el-radio-button>
      <el-radio-button :label="MSG_D2">D2</el-radio-button>
      <el-radio-button :label="MSG_D3">D3</el-radio-button>
    </el-radio-group>
  </div>
</template>
<script>
import { mapGetters , mapActions, mapState} from "vuex";
import {
    MSG_A,
    MSG_B,
    MSG_C,
    MSG_D0,
    MSG_D1,
    MSG_D2,
    MSG_D3,
} from "@/store/index";
export default {
  data(){
    return{
    msgCode : '',
    MSG_A,
    MSG_B,
    MSG_C,
    MSG_D0,
    MSG_D1,
    MSG_D2,
    MSG_D3,
  }},
  computed:{
    ...mapGetters([
      "FORM_DATA",
      'MESSAGE_CODE',
    ]),
  },
  watch:{
    MESSAGE_CODE(code){
      this.msgCode = code
    }
  },
  methods:{
    ...mapActions([
        'updateMessageCode'
    ]),
  }
}
</script>
<style lang="scss">
  .msg-code-control{
    position: fixed;
    bottom:45%;
    right: 0;
    text-align: right;
    z-index: 100000;
    word-break: break-word;
    label{
      display: block;
      border: 1px solid #eee;
      &+label{
        border-top: none;
      }
      .el-radio-button__inner{
        border: none!important;
        width: 100%;
      }
    }
  }
</style>