
<template>

    <div class="ticket-info-container">
        <div class="ticket-info-item" v-if="ticketData.WorkOrderNo">
            <div class="ticket-item-title" style="font-weight: bold;">Aftersale Ticket Number</div>
            <div class="ticket-item-value" v-if="ticketData.WorkOrderNo">
                <span class="value-text" style="color: #00946c">{{ticketData.WorkOrderNo}}</span>
            </div>
        </div>

        <el-divider content-position="left"><h4>Aftersale Type</h4></el-divider>
        <div class="ticket-info-item">
            <div class="ticket-item-title">Aftersale Type</div>
            <div class="ticket-item-value" v-if="ticketData.AfterSaleType">
                <span class="value-text">{{ticketData.AfterSaleType}}</span>
                <template v-if="ticketData.AfterSaleType === 'Replace' || ticketData.AfterSaleType === 'Repair'">
                    <span class="value-text" v-if="IS_NEED_RETURN">Need to return </span>
                    <span class="value-text" v-else>No Need to return</span>
                    <span class="value-text" v-if="IS_NEED_RETURN && ticketData.ReturnPayer">Return fee covered by {{ticketData.ReturnPayer}}</span>
                    
                </template>
                 <template v-if="ticketData.AfterSaleType === 'Send Accessories'">
                    <span class="value-text" v-if="ticketData.SendAccessory">{{ticketData.SendAccessory}}</span>
                    <span class="value-text" v-if="ticketData.SendAccessory === 'Antenna' && ticketData.AntennaModel">{{ticketData.AntennaModel}}</span>
                    <span class="value-text" v-if="ticketData.SendAccessory === 'Other' && ticketData.OtherAccessory">{{ticketData.OtherAccessory}}</span>
                </template>
            </div>
        </div>

         <el-divider content-position="left"><h4>Product</h4></el-divider>

        <div class="ticket-info-item">
            <div class="ticket-item-title">Product Name</div>
            <div class="ticket-item-value">SenseCAP M1</div>
        </div>

        <div class="ticket-info-item">
            <div class="ticket-item-title">Serial Number (SN)</div>
            <div class="ticket-item-value">{{ticketData.SNCode}}</div>
        </div>
        
        <!-- Support Channel -->
        <div class="ticket-info-item">
            <div class="ticket-item-title">Support Channel</div>
            <div class="ticket-item-value">
                <span class="value-text">{{ticketData.SupportChannelType}}</span>
            </div>
        </div>

        <div class="ticket-info-item" v-if="ticketData.SupportChannelType === 'Discord'">
            <div class="ticket-item-title">Discord ID / Ticket Number</div>
            <div class="ticket-item-value">
                <span class="value-text">{{ticketData.SupportChannelText}}</span>
            </div>
        </div>

        <div class="ticket-info-item" v-if="ticketData.SupportChannelType === 'Email'">
            <div class="ticket-item-title">Support Channel Email</div>
            <div class="ticket-item-value">
                <span class="value-text">{{ticketData.SupportChannelText}}</span>
            </div>
        </div>

        <div class="ticket-info-item" v-if="ticketData.SupportChannelType === 'Other'">
            <div class="ticket-item-title">Other Support Channel</div>
            <div class="ticket-item-value">
                <span class="value-text">{{ticketData.SupportChannelText}}</span>
            </div>
        </div>

        <!-- Purchasing Channel -->
        <div class="ticket-info-item">
            <div class="ticket-item-title">Purchasing Channel</div>
            <div class="ticket-item-value">
                <span class="value-text">{{ticketData.BuyChannelType}}</span>
            </div>
        </div>

        <div class="ticket-info-item">
            <div class="ticket-item-title">Order Number</div>
            <div class="ticket-item-value">
                <span class="value-text">{{ticketData.OrderNo}}</span>
            </div>
        </div>

        <div class="ticket-info-item">
            <div class="ticket-item-title">Order image</div>
            <div class="ticket-item-value">
                <div><a  style="font-size:12px;color:#00946c;padding-bottom:5px;font-weight:bold" :href="ticketData.BuyScreenShot" target="_blank" >{{ticketData.BuyScreenShot}}</a></div>
            </div>
        </div>

        <el-divider content-position="left"><h4>Product Issue</h4></el-divider>
        <div class="ticket-info-item">
            <div class="ticket-item-title">Defective Part</div>
            <div class="ticket-item-value">
                <span class="value-text">{{ticketData.FaultType}}</span>
                <span class="value-text" v-if="ticketData.FaultType === 'Accessories'">{{ticketData.FaultAccessoryType}}</span>
                <span class="value-text" v-if="ticketData.FaultType === 'Other'">{{ticketData.FaultOtherText}}</span>
            </div>
        </div>

        <div class="ticket-info-item">
            <div class="ticket-item-title">Issue Description</div>
            <div class="ticket-item-value">{{ticketData.ProblemDescription}}</div>
        </div>
        

        <div class="ticket-info-item">
            <div class="ticket-item-title">Issue images</div>
            <div class="ticket-item-value">
                <a v-for="(item, index)  in ticketData.Picture" 
                    :href="item.PictureUrl" target="_blank" :key="index" 
                    style="display:block;font-size:12px;color:#00946c;padding-bottom:5px;font-weight:bold">{{item.PictureUrl}}</a>
            </div>
        </div>

        <!-- return address -->
        <el-divider content-position="left"> <h4>Return Address</h4></el-divider>
        <div class="ticket-info-item">
            <div class="ticket-item-title">Name</div>
            <div class="ticket-item-value">{{ticketData.RecMan}}</div>
        </div>
        <div class="ticket-info-item">
            <div class="ticket-item-title">Tel</div>
            <div class="ticket-item-value">{{ticketData.RecPhone}}</div>
        </div>
        <div class="ticket-info-item">
            <div class="ticket-item-title">Email</div>
            <div class="ticket-item-value">{{ticketData.RecEmail}}</div>
        </div>
        <div class="ticket-info-item">
            <div class="ticket-item-title">Country</div>
            <div class="ticket-item-value">{{ticketData.RecCountryCode ?  countryOptions[ticketData.RecCountryCode] ? countryOptions[ticketData.RecCountryCode]['name'] : ticketData.RecCountryCode :''}}</div>
        </div>
        <div class="ticket-info-item">
            <div class="ticket-item-title">State</div>
            <div class="ticket-item-value">{{ticketData.RecState}}</div>
        </div>
        <div class="ticket-info-item">
            <div class="ticket-item-title">Street</div>
            <div class="ticket-item-value">{{ticketData.RecAddr}}</div>
        </div>
        <div class="ticket-info-item">
            <div class="ticket-item-title">Postcode</div>
            <div class="ticket-item-value">{{ticketData.RecPostCode}}</div>
        </div>

        <template v-if="visibleDHLEmail">
            <el-divider content-position="left"> <h4>DHL Account</h4></el-divider>
            <div class="ticket-info-item">
                <div class="ticket-item-title">DHL registered email</div>
                <div class="ticket-item-value">
                    {{ticketData.DHLEmail}}
                    <div v-if="IS_DHL_APPROVED" style="color:#00946c;margin-top:5px;"> <i class="el-icon-success" style="margin-right:2px"></i> Your account has been approved</div>
                </div>
               
            </div>
        </template>

        <template v-if="visibleDHLNum">
            <el-divider content-position="left"> <h4>Shipment Information</h4></el-divider>
            <div class="ticket-info-item">
                <div class="ticket-item-title">{{IS_SEEED_PAYEE ? 'Tracking number (DHL)' : 'Tracking number (DHL/FedEx)'}}</div>
                <div class="ticket-item-value">{{ticketData.SendTrackingNo}}</div>
            </div>
        </template>


        <template v-if="visibleRecTracking">
            <el-divider content-position="left"> <h4>Receive tracking info</h4></el-divider>
            <div class="ticket-info-item">
                <div class="ticket-item-title">Tracking number</div>
                <div class="ticket-item-value">{{ticketData.RecTrackingNo}}</div>
            </div>

            <div class="ticket-info-item">
                <div class="ticket-item-title">Carrier name</div>
                <div class="ticket-item-value">{{ticketData.CarrierName}}</div>
            </div>
        </template>
            


    </div>
</template>
<script>
import Options from "@/components/supportForm/options"
import { mapGetters , mapActions, mapState} from "vuex";
import {
    CREATE_WORK_ORDER,
    SAVE_WORK_ORDER,
    UPDATE_WORK_ORDER,
    SUBMIT_WORK_ORDER,
    MSG_A,
    MSG_B,
    MSG_C,
    MSG_D0,
    MSG_D1,
    MSG_D2,
    MSG_D3,
    } from "@/store/index";

const {countryOptions} = Options;

export default {
  components: {},
  data() {
    return { countryOptions};
  },


  computed:{
       ...mapGetters([
          'MESSAGE_CODE',
          'IS_NEED_RETURN',
          'IS_SEEED_PAYEE',
          'IS_DHL_APPROVED'
        ]),
        ...mapGetters({ticketData : 'TICKET_DATA'}),

        // Rec tracking
        visibleRecTracking(){return this.MESSAGE_CODE === MSG_D3 && this.ticketData.RecTrackingNo},

        // DHL邮箱
        visibleDHLEmail(){return this.IS_NEED_RETURN && this.IS_SEEED_PAYEE },

        // DHL运单号输入框
        visibleDHLNum(){return this.IS_NEED_RETURN && (this.MESSAGE_CODE === MSG_D1 || this.MESSAGE_CODE === MSG_D2 || this.MESSAGE_CODE === MSG_D3 )},
        // ...mapGetters(  {ticketData : 'FORM_DATA'}),

  }

};
</script>
<style lang="scss">
  @import "../../assets/css/mixin.scss";
    .ticket-info-container{ 
        @include divInit(); 
        border: 1px solid #eee; 
        background-color: #fff;
        margin-bottom: 20px;
        padding:20px 0
        }
    .ticket-info-item{
        @include divInit(100%,auto,10px 10px , 0 0 0 0);
        display: flex;
        @media screen and (max-width:550px) {
            flex-wrap: wrap;
        }
        // border-bottom: 1px solid #eee;
        // &:last-child{border-bottom: none;}
        .ticket-item-title{
            flex: 1 0 auto;
            max-width: 230px;
            padding-right: 15px;
            line-height: 20px;
            font-size: 14px;
            text-align: right;
            color: #000;
            font-style: italic;
            box-sizing: border-box;
            &:after{
                content:' :'
            }
            
             @media screen and (max-width:550px) {
                text-align: left;
                flex:  0 0 100%;
                max-width: 100%;
                padding: 10px 0 ;
                font-style: normal;
                font-weight: bold;
            }
        }
        .ticket-item-value{
            flex:  1 0 auto;
            max-width: calc(100% - 245px);
            padding:0 10px;
            box-sizing: border-box;
            line-height: 20px;
            font-size: 14px;
            color: #000;
            font-weight: bold;
            word-break: break-word;
             @media screen and (max-width:550px) {
                flex:  0 0 100%;
                max-width: 100%;
                padding-left: 20px;
                font-weight: normal;
            }
            .value-text{
                &+.value-text:before{
                    content :' / '
                }
            }
            img{
                @include inlineBlock(top);
                max-width: 100px;
                margin-right: 5px;
                margin-bottom: 5px;
            }
        }
    }


</style>

