<template>
  <div class="page-container" >
    <template v-if="enableContinue">
      <order-status-panel></order-status-panel>
      <order-status v-if="!VISIBLE_TICKET_STATUS_PANEL"></order-status>
      <support-form v-if="!VISIBLE_TICKET_STATUS_PANEL"></support-form>
      <fae-form></fae-form>
      <el-backtop></el-backtop>
    </template>
    <verify-sn v-if="isLoaded && !enableContinue"></verify-sn>
    <notice-panel v-if="visibleNoticePanel"></notice-panel>
    <set-msg-code v-if="IS_DEV"></set-msg-code>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters, mapActions, mapMutations } from "vuex";
import SupportForm from "@/components/supportForm"
import FaeForm from "@/components/faeForm"
import SetMsgCode from "@/components/setMsgCode"
import VerifySn from "@/components/verifySn"
import OrderStatus from "@/components/orderStatus/bar"
import OrderStatusPanel from "@/components/orderStatus"
import NoticePanel from "@/components/noticePanel"
import {
    MSG_A,
  } from "@/store/index";
export default {
  components: { SupportForm ,FaeForm ,VerifySn,OrderStatus,NoticePanel,OrderStatusPanel,SetMsgCode},
  data() {
    return {
        isLoaded : false
     };
  },

  computed: {
    ...mapGetters([
       'IS_VERIFY_SN',
       'MESSAGE_CODE',
       'IS_READED_NOTICE',
       'VISIBLE_TICKET_STATUS_PANEL',
       'IS_DEV'
    ]),
    enableContinue(){
      return (this.IS_READED_NOTICE && this.MESSAGE_CODE && this.IS_VERIFY_SN)
    },

    visibleNoticePanel(){
       return !this.IS_READED_NOTICE && this.isLoaded 
    },

  },
  watch: { },
  methods: {
    ...mapActions([ 'getWorkOrderData']),
    async init(){
      this.isLoaded = await this.getWorkOrderData();
    }
  },
  created() {
    this.init();
  },
};
</script>
<style  lang="scss">
  @import "../assets/css/mixin.scss";
  @import "../assets/css/form.scss";
  .page-container{
    padding-bottom: percentage(30 / 1200);
  }
  #app{

    font-family: Arial, Helvetica, sans-serif;
  }
   .logo{
        width:100%;
        text-align: center;
        margin: 20px auto;
        img{
            width: 35%;
            max-width: 250px;
            min-width: 200px;
            height: auto;
            vertical-align: middle;
        }
    }
</style>
