<template>
  <div id="app">
    <Support />
  </div>
</template>
<script>
import Support from '@/views/Index'

export default ({
  components:{Support}
})
</script>



