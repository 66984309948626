export default {
    // 支持渠道
    supportChannelOptions: [
        { label: "Discord", value: "Discord" },
        { label: "Email", value: "Email" },
        { label: "Other", value: "Other" },
      ],

    // 购买渠道
    purchasingChannelOptions: [
      { value: "Seeed Online Store", label: "Seeed Online Store" },
      { value: "Distributor", label: "Distributor" },
    ],

    // 故障类型
    faultTypeOptions: [
      { label: "Hotspot", value: "Hotspot" },
      { label: "Accessories", value: "Accessories" },
      { label: "Other", value: "Other" },
    ],

    // 配件故障类型
    faultAccessOptions: [
      { label: "Antenna", value: "Antenna" },
      { label: "micro SD card", value: "micro SD card" },
      { label: "Adaptor", value: "Adaptor" },
    ],

    // 售后类型
    aftersaleTypeOptions: [
      { label: "Replace", value: "Replace" },
      { label: "Repair", value: "Repair" },
      { label: "Send Accessories", value: "Send Accessories" },
    ],
   
   // 替换类型
    replaceOptions : [
        { label: "Need to return", value: "1"},
        { label: "No need to return", value: "0" },
    ],

   // 运费承担者
    returnFeeOptions : [
        { label: "Return fee covered by Seeed", value: "Seeed" },
        { label: "Return fee covered by User", value: "User" }
    ],

    // 配件类型
    accessoriesOptions: [
      { label: "Cool Fan Set", value: "Cool Fan Set" },
      { label: "Micro SD Card Set", value: "Micro SD Card Set" },
      { label: "Antenna", value: 'Antenna'},
      { label: "Power Adaptor", value: 'Power Adaptor'},
      { label: "WM1302 / WM1303", value: 'WM1302 / WM1303'},
      { label: "Others", value: 'Other'}
    ],

    // Antenna
    antennaOptions: [
      { label: "EU868", value: "EU868" },
      { label: "US915", value: "US915" },
    ],

    // country
    countryOptions:{
      "AD": {
          "name": "Andorra",
          "code": "AD"
      },
      "AE": {
          "name": "United Arab Emirates",
          "code": "AE"
      },
      "AF": {
          "name": "Afghanistan",
          "code": "AF"
      },
      "AG": {
          "name": "Antigua & Barbuda",
          "code": "AG"
      },
      "AI": {
          "name": "Anguilla",
          "code": "AI"
      },
      "AL": {
          "name": "Albania",
          "code": "AL"
      },
      "AM": {
          "name": "Armenia",
          "code": "AM"
      },
      "AO": {
          "name": "Angola",
          "code": "AO"
      },
      "AQ": {
          "name": "Antarctica",
          "code": "AQ"
      },
      "AR": {
          "name": "Argentina",
          "code": "AR"
      },
      "AS": {
          "name": "American Samoa",
          "code": "AS"
      },
      "AT": {
          "name": "Austria",
          "code": "AT",
          "regions": [
              {
                  "code": "WI",
                  "name": "Wien",
                  "index": "95"
              },
              {
                  "code": "NO",
                  "name": "Niederosterreich",
                  "index": "96"
              },
              {
                  "code": "OO",
                  "name": "Oberosterreich",
                  "index": "97"
              },
              {
                  "code": "SB",
                  "name": "Salzburg",
                  "index": "98"
              },
              {
                  "code": "KN",
                  "name": "Karnten",
                  "index": "99"
              },
              {
                  "code": "ST",
                  "name": "Steiermark",
                  "index": "100"
              },
              {
                  "code": "TI",
                  "name": "Tirol",
                  "index": "101"
              },
              {
                  "code": "BL",
                  "name": "Burgenland",
                  "index": "102"
              },
              {
                  "code": "VB",
                  "name": "Vorarlberg",
                  "index": "103"
              }
          ]
      },
      "AU": {
          "name": "Australia",
          "code": "AU"
      },
      "AW": {
          "name": "Aruba",
          "code": "AW"
      },
      "AX": {
          "name": "Åland Islands",
          "code": "AX"
      },
      "AZ": {
          "name": "Azerbaijan",
          "code": "AZ"
      },
      "BA": {
          "name": "Bosnia & Herzegovina",
          "code": "BA"
      },
      "BB": {
          "name": "Barbados",
          "code": "BB"
      },
      "BD": {
          "name": "Bangladesh",
          "code": "BD"
      },
      "BE": {
          "name": "Belgium",
          "code": "BE"
      },
      "BF": {
          "name": "Burkina Faso",
          "code": "BF"
      },
      "BG": {
          "name": "Bulgaria",
          "code": "BG"
      },
      "BH": {
          "name": "Bahrain",
          "code": "BH"
      },
      "BI": {
          "name": "Burundi",
          "code": "BI"
      },
      "BJ": {
          "name": "Benin",
          "code": "BJ"
      },
      "BL": {
          "name": "St. Barthélemy",
          "code": "BL"
      },
      "BM": {
          "name": "Bermuda",
          "code": "BM"
      },
      "BN": {
          "name": "Brunei",
          "code": "BN"
      },
      "BO": {
          "name": "Bolivia",
          "code": "BO"
      },
      "BR": {
          "name": "Brazil",
          "code": "BR",
          "regions": [
              {
                  "code": "AC",
                  "name": "Acre",
                  "index": "485"
              },
              {
                  "code": "AL",
                  "name": "Alagoas",
                  "index": "486"
              },
              {
                  "code": "AP",
                  "name": "Amapa",
                  "index": "487"
              },
              {
                  "code": "AM",
                  "name": "Amazonas",
                  "index": "488"
              },
              {
                  "code": "BA",
                  "name": "Bahia",
                  "index": "489"
              },
              {
                  "code": "CE",
                  "name": "Ceara",
                  "index": "490"
              },
              {
                  "code": "ES",
                  "name": "Espirito Santo",
                  "index": "491"
              },
              {
                  "code": "GO",
                  "name": "Goias",
                  "index": "492"
              },
              {
                  "code": "MA",
                  "name": "Maranhao",
                  "index": "493"
              },
              {
                  "code": "MT",
                  "name": "Mato Grosso",
                  "index": "494"
              },
              {
                  "code": "MS",
                  "name": "Mato Grosso do Sul",
                  "index": "495"
              },
              {
                  "code": "MG",
                  "name": "Minas Gerais",
                  "index": "496"
              },
              {
                  "code": "PA",
                  "name": "Para",
                  "index": "497"
              },
              {
                  "code": "PB",
                  "name": "Paraiba",
                  "index": "498"
              },
              {
                  "code": "PR",
                  "name": "Parana",
                  "index": "499"
              },
              {
                  "code": "PE",
                  "name": "Pernambuco",
                  "index": "500"
              },
              {
                  "code": "PI",
                  "name": "Piaui",
                  "index": "501"
              },
              {
                  "code": "RJ",
                  "name": "Rio de Janeiro",
                  "index": "502"
              },
              {
                  "code": "RN",
                  "name": "Rio Grande do Norte",
                  "index": "503"
              },
              {
                  "code": "RS",
                  "name": "Rio Grande do Sul",
                  "index": "504"
              },
              {
                  "code": "RO",
                  "name": "Rondonia",
                  "index": "505"
              },
              {
                  "code": "RR",
                  "name": "Roraima",
                  "index": "506"
              },
              {
                  "code": "SC",
                  "name": "Santa Catarina",
                  "index": "507"
              },
              {
                  "code": "SP",
                  "name": "Sao Paulo",
                  "index": "508"
              },
              {
                  "code": "SE",
                  "name": "Sergipe",
                  "index": "509"
              },
              {
                  "code": "TO",
                  "name": "Tocantins",
                  "index": "510"
              },
              {
                  "code": "DF",
                  "name": "Distrito Federal",
                  "index": "511"
              }
          ]
      },
      "BS": {
          "name": "Bahamas",
          "code": "BS"
      },
      "BT": {
          "name": "Bhutan",
          "code": "BT"
      },
      "BV": {
          "name": "Bouvet Island",
          "code": "BV"
      },
      "BW": {
          "name": "Botswana",
          "code": "BW"
      },
      "BY": {
          "name": "Belarus",
          "code": "BY"
      },
      "BZ": {
          "name": "Belize",
          "code": "BZ"
      },
      "CA": {
          "name": "Canada",
          "code": "CA",
          "regions": [
              {
                  "code": "AB",
                  "name": "Alberta",
                  "index": "66"
              },
              {
                  "code": "BC",
                  "name": "British Columbia",
                  "index": "67"
              },
              {
                  "code": "MB",
                  "name": "Manitoba",
                  "index": "68"
              },
              {
                  "code": "NL",
                  "name": "Newfoundland and Labrador",
                  "index": "69"
              },
              {
                  "code": "NB",
                  "name": "New Brunswick",
                  "index": "70"
              },
              {
                  "code": "NS",
                  "name": "Nova Scotia",
                  "index": "71"
              },
              {
                  "code": "NT",
                  "name": "Northwest Territories",
                  "index": "72"
              },
              {
                  "code": "NU",
                  "name": "Nunavut",
                  "index": "73"
              },
              {
                  "code": "ON",
                  "name": "Ontario",
                  "index": "74"
              },
              {
                  "code": "PE",
                  "name": "Prince Edward Island",
                  "index": "75"
              },
              {
                  "code": "QC",
                  "name": "Quebec",
                  "index": "76"
              },
              {
                  "code": "SK",
                  "name": "Saskatchewan",
                  "index": "77"
              },
              {
                  "code": "YT",
                  "name": "Yukon Territory",
                  "index": "78"
              }
          ]
      },
      "CC": {
          "name": "Cocos (Keeling) Islands",
          "code": "CC"
      },
      "CD": {
          "name": "Congo - Kinshasa",
          "code": "CD"
      },
      "CF": {
          "name": "Central African Republic",
          "code": "CF"
      },
      "CG": {
          "name": "Congo - Brazzaville",
          "code": "CG"
      },
      "CH": {
          "name": "Switzerland",
          "code": "CH",
          "regions": [
              {
                  "code": "AG",
                  "name": "Aargau",
                  "index": "104"
              },
              {
                  "code": "AI",
                  "name": "Appenzell Innerrhoden",
                  "index": "105"
              },
              {
                  "code": "AR",
                  "name": "Appenzell Ausserrhoden",
                  "index": "106"
              },
              {
                  "code": "BE",
                  "name": "Bern",
                  "index": "107"
              },
              {
                  "code": "BL",
                  "name": "Basel-Landschaft",
                  "index": "108"
              },
              {
                  "code": "BS",
                  "name": "Basel-Stadt",
                  "index": "109"
              },
              {
                  "code": "FR",
                  "name": "Freiburg",
                  "index": "110"
              },
              {
                  "code": "GE",
                  "name": "Genf",
                  "index": "111"
              },
              {
                  "code": "GL",
                  "name": "Glarus",
                  "index": "112"
              },
              {
                  "code": "GR",
                  "name": "Graubunden",
                  "index": "113"
              },
              {
                  "code": "JU",
                  "name": "Jura",
                  "index": "114"
              },
              {
                  "code": "LU",
                  "name": "Luzern",
                  "index": "115"
              },
              {
                  "code": "NE",
                  "name": "Neuenburg",
                  "index": "116"
              },
              {
                  "code": "NW",
                  "name": "Nidwalden",
                  "index": "117"
              },
              {
                  "code": "OW",
                  "name": "Obwalden",
                  "index": "118"
              },
              {
                  "code": "SG",
                  "name": "St. Gallen",
                  "index": "119"
              },
              {
                  "code": "SH",
                  "name": "Schaffhausen",
                  "index": "120"
              },
              {
                  "code": "SO",
                  "name": "Solothurn",
                  "index": "121"
              },
              {
                  "code": "SZ",
                  "name": "Schwyz",
                  "index": "122"
              },
              {
                  "code": "TG",
                  "name": "Thurgau",
                  "index": "123"
              },
              {
                  "code": "TI",
                  "name": "Tessin",
                  "index": "124"
              },
              {
                  "code": "UR",
                  "name": "Uri",
                  "index": "125"
              },
              {
                  "code": "VD",
                  "name": "Waadt",
                  "index": "126"
              },
              {
                  "code": "VS",
                  "name": "Wallis",
                  "index": "127"
              },
              {
                  "code": "ZG",
                  "name": "Zug",
                  "index": "128"
              },
              {
                  "code": "ZH",
                  "name": "Zurich",
                  "index": "129"
              }
          ]
      },
      "CI": {
          "name": "Côte d’Ivoire",
          "code": "CI"
      },
      "CK": {
          "name": "Cook Islands",
          "code": "CK"
      },
      "CL": {
          "name": "Chile",
          "code": "CL"
      },
      "CM": {
          "name": "Cameroon",
          "code": "CM"
      },
      "CO": {
          "name": "Colombia",
          "code": "CO"
      },
      "CR": {
          "name": "Costa Rica",
          "code": "CR"
      },
      "CU": {
          "name": "Cuba",
          "code": "CU"
      },
      "CV": {
          "name": "Cape Verde",
          "code": "CV"
      },
      "CX": {
          "name": "Christmas Island",
          "code": "CX"
      },
      "CY": {
          "name": "Cyprus",
          "code": "CY"
      },
      "CZ": {
          "name": "Czechia",
          "code": "CZ"
      },
      "DE": {
          "name": "Germany",
          "code": "DE",
          "regions": [
              {
                  "code": "NDS",
                  "name": "Niedersachsen",
                  "index": "79"
              },
              {
                  "code": "BAW",
                  "name": "Baden-Wurttemberg",
                  "index": "80"
              },
              {
                  "code": "BAY",
                  "name": "Bayern",
                  "index": "81"
              },
              {
                  "code": "BER",
                  "name": "Berlin",
                  "index": "82"
              },
              {
                  "code": "BRG",
                  "name": "Brandenburg",
                  "index": "83"
              },
              {
                  "code": "BRE",
                  "name": "Bremen",
                  "index": "84"
              },
              {
                  "code": "HAM",
                  "name": "Hamburg",
                  "index": "85"
              },
              {
                  "code": "HES",
                  "name": "Hessen",
                  "index": "86"
              },
              {
                  "code": "MEC",
                  "name": "Mecklenburg-Vorpommern",
                  "index": "87"
              },
              {
                  "code": "NRW",
                  "name": "Nordrhein-Westfalen",
                  "index": "88"
              },
              {
                  "code": "RHE",
                  "name": "Rheinland-Pfalz",
                  "index": "89"
              },
              {
                  "code": "SAR",
                  "name": "Saarland",
                  "index": "90"
              },
              {
                  "code": "SAS",
                  "name": "Sachsen",
                  "index": "91"
              },
              {
                  "code": "SAC",
                  "name": "Sachsen-Anhalt",
                  "index": "92"
              },
              {
                  "code": "SCN",
                  "name": "Schleswig-Holstein",
                  "index": "93"
              },
              {
                  "code": "THE",
                  "name": "Thuringen",
                  "index": "94"
              }
          ]
      },
      "DJ": {
          "name": "Djibouti",
          "code": "DJ"
      },
      "DK": {
          "name": "Denmark",
          "code": "DK"
      },
      "DM": {
          "name": "Dominica",
          "code": "DM"
      },
      "DO": {
          "name": "Dominican Republic",
          "code": "DO"
      },
      "DZ": {
          "name": "Algeria",
          "code": "DZ"
      },
      "EC": {
          "name": "Ecuador",
          "code": "EC"
      },
      "EE": {
          "name": "Estonia",
          "code": "EE",
          "regions": [
              {
                  "code": "EE-37",
                  "name": "Harjumaa",
                  "index": "340"
              },
              {
                  "code": "EE-39",
                  "name": "Hiiumaa",
                  "index": "341"
              },
              {
                  "code": "EE-44",
                  "name": "Ida-Virumaa",
                  "index": "342"
              },
              {
                  "code": "EE-49",
                  "name": "Jogevamaa",
                  "index": "343"
              },
              {
                  "code": "EE-51",
                  "name": "Jarvamaa",
                  "index": "344"
              },
              {
                  "code": "EE-57",
                  "name": "Laanemaa",
                  "index": "345"
              },
              {
                  "code": "EE-59",
                  "name": "Laane-Virumaa",
                  "index": "346"
              },
              {
                  "code": "EE-65",
                  "name": "Polvamaa",
                  "index": "347"
              },
              {
                  "code": "EE-67",
                  "name": "Parnumaa",
                  "index": "348"
              },
              {
                  "code": "EE-70",
                  "name": "Raplamaa",
                  "index": "349"
              },
              {
                  "code": "EE-74",
                  "name": "Saaremaa",
                  "index": "350"
              },
              {
                  "code": "EE-78",
                  "name": "Tartumaa",
                  "index": "351"
              },
              {
                  "code": "EE-82",
                  "name": "Valgamaa",
                  "index": "352"
              },
              {
                  "code": "EE-84",
                  "name": "Viljandimaa",
                  "index": "353"
              },
              {
                  "code": "EE-86",
                  "name": "Vorumaa",
                  "index": "354"
              }
          ]
      },
      "EG": {
          "name": "Egypt",
          "code": "EG"
      },
      "EH": {
          "name": "Western Sahara",
          "code": "EH"
      },
      "ER": {
          "name": "Eritrea",
          "code": "ER"
      },
      "ES": {
          "name": "Spain",
          "code": "ES",
          "regions": [
              {
                  "code": "A Coruсa",
                  "name": "A Coruna",
                  "index": "130"
              },
              {
                  "code": "Alava",
                  "name": "Alava",
                  "index": "131"
              },
              {
                  "code": "Albacete",
                  "name": "Albacete",
                  "index": "132"
              },
              {
                  "code": "Alicante",
                  "name": "Alicante",
                  "index": "133"
              },
              {
                  "code": "Almeria",
                  "name": "Almeria",
                  "index": "134"
              },
              {
                  "code": "Asturias",
                  "name": "Asturias",
                  "index": "135"
              },
              {
                  "code": "Avila",
                  "name": "Avila",
                  "index": "136"
              },
              {
                  "code": "Badajoz",
                  "name": "Badajoz",
                  "index": "137"
              },
              {
                  "code": "Baleares",
                  "name": "Baleares",
                  "index": "138"
              },
              {
                  "code": "Barcelona",
                  "name": "Barcelona",
                  "index": "139"
              },
              {
                  "code": "Burgos",
                  "name": "Burgos",
                  "index": "140"
              },
              {
                  "code": "Caceres",
                  "name": "Caceres",
                  "index": "141"
              },
              {
                  "code": "Cadiz",
                  "name": "Cadiz",
                  "index": "142"
              },
              {
                  "code": "Cantabria",
                  "name": "Cantabria",
                  "index": "143"
              },
              {
                  "code": "Castellon",
                  "name": "Castellon",
                  "index": "144"
              },
              {
                  "code": "Ceuta",
                  "name": "Ceuta",
                  "index": "145"
              },
              {
                  "code": "Ciudad Real",
                  "name": "Ciudad Real",
                  "index": "146"
              },
              {
                  "code": "Cordoba",
                  "name": "Cordoba",
                  "index": "147"
              },
              {
                  "code": "Cuenca",
                  "name": "Cuenca",
                  "index": "148"
              },
              {
                  "code": "Girona",
                  "name": "Girona",
                  "index": "149"
              },
              {
                  "code": "Granada",
                  "name": "Granada",
                  "index": "150"
              },
              {
                  "code": "Guadalajara",
                  "name": "Guadalajara",
                  "index": "151"
              },
              {
                  "code": "Guipuzcoa",
                  "name": "Guipuzcoa",
                  "index": "152"
              },
              {
                  "code": "Huelva",
                  "name": "Huelva",
                  "index": "153"
              },
              {
                  "code": "Huesca",
                  "name": "Huesca",
                  "index": "154"
              },
              {
                  "code": "Jaen",
                  "name": "Jaen",
                  "index": "155"
              },
              {
                  "code": "La Rioja",
                  "name": "La Rioja",
                  "index": "156"
              },
              {
                  "code": "Las Palmas",
                  "name": "Las Palmas",
                  "index": "157"
              },
              {
                  "code": "Leon",
                  "name": "Leon",
                  "index": "158"
              },
              {
                  "code": "Lleida",
                  "name": "Lleida",
                  "index": "159"
              },
              {
                  "code": "Lugo",
                  "name": "Lugo",
                  "index": "160"
              },
              {
                  "code": "Madrid",
                  "name": "Madrid",
                  "index": "161"
              },
              {
                  "code": "Malaga",
                  "name": "Malaga",
                  "index": "162"
              },
              {
                  "code": "Melilla",
                  "name": "Melilla",
                  "index": "163"
              },
              {
                  "code": "Murcia",
                  "name": "Murcia",
                  "index": "164"
              },
              {
                  "code": "Navarra",
                  "name": "Navarra",
                  "index": "165"
              },
              {
                  "code": "Ourense",
                  "name": "Ourense",
                  "index": "166"
              },
              {
                  "code": "Palencia",
                  "name": "Palencia",
                  "index": "167"
              },
              {
                  "code": "Pontevedra",
                  "name": "Pontevedra",
                  "index": "168"
              },
              {
                  "code": "Salamanca",
                  "name": "Salamanca",
                  "index": "169"
              },
              {
                  "code": "Santa Cruz de Tenerife",
                  "name": "Santa Cruz de Tenerife",
                  "index": "170"
              },
              {
                  "code": "Segovia",
                  "name": "Segovia",
                  "index": "171"
              },
              {
                  "code": "Sevilla",
                  "name": "Sevilla",
                  "index": "172"
              },
              {
                  "code": "Soria",
                  "name": "Soria",
                  "index": "173"
              },
              {
                  "code": "Tarragona",
                  "name": "Tarragona",
                  "index": "174"
              },
              {
                  "code": "Teruel",
                  "name": "Teruel",
                  "index": "175"
              },
              {
                  "code": "Toledo",
                  "name": "Toledo",
                  "index": "176"
              },
              {
                  "code": "Valencia",
                  "name": "Valencia",
                  "index": "177"
              },
              {
                  "code": "Valladolid",
                  "name": "Valladolid",
                  "index": "178"
              },
              {
                  "code": "Vizcaya",
                  "name": "Vizcaya",
                  "index": "179"
              },
              {
                  "code": "Zamora",
                  "name": "Zamora",
                  "index": "180"
              },
              {
                  "code": "Zaragoza",
                  "name": "Zaragoza",
                  "index": "181"
              }
          ]
      },
      "ET": {
          "name": "Ethiopia",
          "code": "ET"
      },
      "FI": {
          "name": "Finland",
          "code": "FI",
          "regions": [
              {
                  "code": "Lappi",
                  "name": "Lappi",
                  "index": "320"
              },
              {
                  "code": "Pohjois-Pohjanmaa",
                  "name": "Pohjois-Pohjanmaa",
                  "index": "321"
              },
              {
                  "code": "Kainuu",
                  "name": "Kainuu",
                  "index": "322"
              },
              {
                  "code": "Pohjois-Karjala",
                  "name": "Pohjois-Karjala",
                  "index": "323"
              },
              {
                  "code": "Pohjois-Savo",
                  "name": "Pohjois-Savo",
                  "index": "324"
              },
              {
                  "code": "Etelä-Savo",
                  "name": "Etela-Savo",
                  "index": "325"
              },
              {
                  "code": "Etelä-Pohjanmaa",
                  "name": "Etela-Pohjanmaa",
                  "index": "326"
              },
              {
                  "code": "Pohjanmaa",
                  "name": "Pohjanmaa",
                  "index": "327"
              },
              {
                  "code": "Pirkanmaa",
                  "name": "Pirkanmaa",
                  "index": "328"
              },
              {
                  "code": "Satakunta",
                  "name": "Satakunta",
                  "index": "329"
              },
              {
                  "code": "Keski-Pohjanmaa",
                  "name": "Keski-Pohjanmaa",
                  "index": "330"
              },
              {
                  "code": "Keski-Suomi",
                  "name": "Keski-Suomi",
                  "index": "331"
              },
              {
                  "code": "Varsinais-Suomi",
                  "name": "Varsinais-Suomi",
                  "index": "332"
              },
              {
                  "code": "Etelä-Karjala",
                  "name": "Etela-Karjala",
                  "index": "333"
              },
              {
                  "code": "Päijät-Häme",
                  "name": "Paijat-Hame",
                  "index": "334"
              },
              {
                  "code": "Kanta-Häme",
                  "name": "Kanta-Hame",
                  "index": "335"
              },
              {
                  "code": "Uusimaa",
                  "name": "Uusimaa",
                  "index": "336"
              },
              {
                  "code": "Itä-Uusimaa",
                  "name": "Ita-Uusimaa",
                  "index": "337"
              },
              {
                  "code": "Kymenlaakso",
                  "name": "Kymenlaakso",
                  "index": "338"
              },
              {
                  "code": "Ahvenanmaa",
                  "name": "Ahvenanmaa",
                  "index": "339"
              }
          ]
      },
      "FJ": {
          "name": "Fiji",
          "code": "FJ"
      },
      "FK": {
          "name": "Falkland Islands",
          "code": "FK"
      },
      "FM": {
          "name": "Micronesia",
          "code": "FM"
      },
      "FO": {
          "name": "Faroe Islands",
          "code": "FO"
      },
      "FR": {
          "name": "France",
          "code": "FR",
          "regions": [
              {
                  "code": "1",
                  "name": "Ain",
                  "index": "182"
              },
              {
                  "code": "2",
                  "name": "Aisne",
                  "index": "183"
              },
              {
                  "code": "3",
                  "name": "Allier",
                  "index": "184"
              },
              {
                  "code": "4",
                  "name": "Alpes-de-Haute-Provence",
                  "index": "185"
              },
              {
                  "code": "5",
                  "name": "Hautes-Alpes",
                  "index": "186"
              },
              {
                  "code": "6",
                  "name": "Alpes-Maritimes",
                  "index": "187"
              },
              {
                  "code": "7",
                  "name": "Ardeche",
                  "index": "188"
              },
              {
                  "code": "8",
                  "name": "Ardennes",
                  "index": "189"
              },
              {
                  "code": "9",
                  "name": "Ariege",
                  "index": "190"
              },
              {
                  "code": "10",
                  "name": "Aube",
                  "index": "191"
              },
              {
                  "code": "11",
                  "name": "Aude",
                  "index": "192"
              },
              {
                  "code": "12",
                  "name": "Aveyron",
                  "index": "193"
              },
              {
                  "code": "13",
                  "name": "Bouches-du-Rhone",
                  "index": "194"
              },
              {
                  "code": "14",
                  "name": "Calvados",
                  "index": "195"
              },
              {
                  "code": "15",
                  "name": "Cantal",
                  "index": "196"
              },
              {
                  "code": "16",
                  "name": "Charente",
                  "index": "197"
              },
              {
                  "code": "17",
                  "name": "Charente-Maritime",
                  "index": "198"
              },
              {
                  "code": "18",
                  "name": "Cher",
                  "index": "199"
              },
              {
                  "code": "19",
                  "name": "Correze",
                  "index": "200"
              },
              {
                  "code": "2A",
                  "name": "Corse-du-Sud",
                  "index": "201"
              },
              {
                  "code": "2B",
                  "name": "Haute-Corse",
                  "index": "202"
              },
              {
                  "code": "21",
                  "name": "Cote-d'Or",
                  "index": "203"
              },
              {
                  "code": "22",
                  "name": "Cotes-d'Armor",
                  "index": "204"
              },
              {
                  "code": "23",
                  "name": "Creuse",
                  "index": "205"
              },
              {
                  "code": "24",
                  "name": "Dordogne",
                  "index": "206"
              },
              {
                  "code": "25",
                  "name": "Doubs",
                  "index": "207"
              },
              {
                  "code": "26",
                  "name": "Drome",
                  "index": "208"
              },
              {
                  "code": "27",
                  "name": "Eure",
                  "index": "209"
              },
              {
                  "code": "28",
                  "name": "Eure-et-Loir",
                  "index": "210"
              },
              {
                  "code": "29",
                  "name": "Finistere",
                  "index": "211"
              },
              {
                  "code": "30",
                  "name": "Gard",
                  "index": "212"
              },
              {
                  "code": "31",
                  "name": "Haute-Garonne",
                  "index": "213"
              },
              {
                  "code": "32",
                  "name": "Gers",
                  "index": "214"
              },
              {
                  "code": "33",
                  "name": "Gironde",
                  "index": "215"
              },
              {
                  "code": "34",
                  "name": "Herault",
                  "index": "216"
              },
              {
                  "code": "35",
                  "name": "Ille-et-Vilaine",
                  "index": "217"
              },
              {
                  "code": "36",
                  "name": "Indre",
                  "index": "218"
              },
              {
                  "code": "37",
                  "name": "Indre-et-Loire",
                  "index": "219"
              },
              {
                  "code": "38",
                  "name": "Isere",
                  "index": "220"
              },
              {
                  "code": "39",
                  "name": "Jura",
                  "index": "221"
              },
              {
                  "code": "40",
                  "name": "Landes",
                  "index": "222"
              },
              {
                  "code": "41",
                  "name": "Loir-et-Cher",
                  "index": "223"
              },
              {
                  "code": "42",
                  "name": "Loire",
                  "index": "224"
              },
              {
                  "code": "43",
                  "name": "Haute-Loire",
                  "index": "225"
              },
              {
                  "code": "44",
                  "name": "Loire-Atlantique",
                  "index": "226"
              },
              {
                  "code": "45",
                  "name": "Loiret",
                  "index": "227"
              },
              {
                  "code": "46",
                  "name": "Lot",
                  "index": "228"
              },
              {
                  "code": "47",
                  "name": "Lot-et-Garonne",
                  "index": "229"
              },
              {
                  "code": "48",
                  "name": "Lozere",
                  "index": "230"
              },
              {
                  "code": "49",
                  "name": "Maine-et-Loire",
                  "index": "231"
              },
              {
                  "code": "50",
                  "name": "Manche",
                  "index": "232"
              },
              {
                  "code": "51",
                  "name": "Marne",
                  "index": "233"
              },
              {
                  "code": "52",
                  "name": "Haute-Marne",
                  "index": "234"
              },
              {
                  "code": "53",
                  "name": "Mayenne",
                  "index": "235"
              },
              {
                  "code": "54",
                  "name": "Meurthe-et-Moselle",
                  "index": "236"
              },
              {
                  "code": "55",
                  "name": "Meuse",
                  "index": "237"
              },
              {
                  "code": "56",
                  "name": "Morbihan",
                  "index": "238"
              },
              {
                  "code": "57",
                  "name": "Moselle",
                  "index": "239"
              },
              {
                  "code": "58",
                  "name": "Nievre",
                  "index": "240"
              },
              {
                  "code": "59",
                  "name": "Nord",
                  "index": "241"
              },
              {
                  "code": "60",
                  "name": "Oise",
                  "index": "242"
              },
              {
                  "code": "61",
                  "name": "Orne",
                  "index": "243"
              },
              {
                  "code": "62",
                  "name": "Pas-de-Calais",
                  "index": "244"
              },
              {
                  "code": "63",
                  "name": "Puy-de-Dome",
                  "index": "245"
              },
              {
                  "code": "64",
                  "name": "Pyrenees-Atlantiques",
                  "index": "246"
              },
              {
                  "code": "65",
                  "name": "Hautes-Pyrenees",
                  "index": "247"
              },
              {
                  "code": "66",
                  "name": "Pyrenees-Orientales",
                  "index": "248"
              },
              {
                  "code": "67",
                  "name": "Bas-Rhin",
                  "index": "249"
              },
              {
                  "code": "68",
                  "name": "Haut-Rhin",
                  "index": "250"
              },
              {
                  "code": "69",
                  "name": "Rhone",
                  "index": "251"
              },
              {
                  "code": "70",
                  "name": "Haute-Saone",
                  "index": "252"
              },
              {
                  "code": "71",
                  "name": "Saone-et-Loire",
                  "index": "253"
              },
              {
                  "code": "72",
                  "name": "Sarthe",
                  "index": "254"
              },
              {
                  "code": "73",
                  "name": "Savoie",
                  "index": "255"
              },
              {
                  "code": "74",
                  "name": "Haute-Savoie",
                  "index": "256"
              },
              {
                  "code": "75",
                  "name": "Paris",
                  "index": "257"
              },
              {
                  "code": "76",
                  "name": "Seine-Maritime",
                  "index": "258"
              },
              {
                  "code": "77",
                  "name": "Seine-et-Marne",
                  "index": "259"
              },
              {
                  "code": "78",
                  "name": "Yvelines",
                  "index": "260"
              },
              {
                  "code": "79",
                  "name": "Deux-Sevres",
                  "index": "261"
              },
              {
                  "code": "80",
                  "name": "Somme",
                  "index": "262"
              },
              {
                  "code": "81",
                  "name": "Tarn",
                  "index": "263"
              },
              {
                  "code": "82",
                  "name": "Tarn-et-Garonne",
                  "index": "264"
              },
              {
                  "code": "83",
                  "name": "Var",
                  "index": "265"
              },
              {
                  "code": "84",
                  "name": "Vaucluse",
                  "index": "266"
              },
              {
                  "code": "85",
                  "name": "Vendee",
                  "index": "267"
              },
              {
                  "code": "86",
                  "name": "Vienne",
                  "index": "268"
              },
              {
                  "code": "87",
                  "name": "Haute-Vienne",
                  "index": "269"
              },
              {
                  "code": "88",
                  "name": "Vosges",
                  "index": "270"
              },
              {
                  "code": "89",
                  "name": "Yonne",
                  "index": "271"
              },
              {
                  "code": "90",
                  "name": "Territoire-de-Belfort",
                  "index": "272"
              },
              {
                  "code": "91",
                  "name": "Essonne",
                  "index": "273"
              },
              {
                  "code": "92",
                  "name": "Hauts-de-Seine",
                  "index": "274"
              },
              {
                  "code": "93",
                  "name": "Seine-Saint-Denis",
                  "index": "275"
              },
              {
                  "code": "94",
                  "name": "Val-de-Marne",
                  "index": "276"
              },
              {
                  "code": "95",
                  "name": "Val-d'Oise",
                  "index": "277"
              }
          ]
      },
      "GA": {
          "name": "Gabon",
          "code": "GA"
      },
      "GB": {
          "name": "United Kingdom",
          "code": "GB"
      },
      "GD": {
          "name": "Grenada",
          "code": "GD"
      },
      "GE": {
          "name": "Georgia",
          "code": "GE"
      },
      "GF": {
          "name": "French Guiana",
          "code": "GF"
      },
      "GG": {
          "name": "Guernsey",
          "code": "GG"
      },
      "GH": {
          "name": "Ghana",
          "code": "GH"
      },
      "GI": {
          "name": "Gibraltar",
          "code": "GI"
      },
      "GL": {
          "name": "Greenland",
          "code": "GL"
      },
      "GM": {
          "name": "Gambia",
          "code": "GM"
      },
      "GN": {
          "name": "Guinea",
          "code": "GN"
      },
      "GP": {
          "name": "Guadeloupe",
          "code": "GP"
      },
      "GQ": {
          "name": "Equatorial Guinea",
          "code": "GQ"
      },
      "GR": {
          "name": "Greece",
          "code": "GR"
      },
      "GS": {
          "name": "South Georgia & South Sandwich Islands",
          "code": "GS"
      },
      "GT": {
          "name": "Guatemala",
          "code": "GT"
      },
      "GU": {
          "name": "Guam",
          "code": "GU"
      },
      "GW": {
          "name": "Guinea-Bissau",
          "code": "GW"
      },
      "GY": {
          "name": "Guyana",
          "code": "GY"
      },
      "HK": {
          "name": "Hong Kong SAR China",
          "code": "HK"
      },
      "HM": {
          "name": "Heard & McDonald Islands",
          "code": "HM"
      },
      "HN": {
          "name": "Honduras",
          "code": "HN"
      },
      "HR": {
          "name": "Croatia",
          "code": "HR",
          "regions": [
              {
                  "code": "HR-01",
                  "name": "Zagrebacka zupanija",
                  "index": "512"
              },
              {
                  "code": "HR-02",
                  "name": "Krapinsko-zagorska zupanija",
                  "index": "513"
              },
              {
                  "code": "HR-03",
                  "name": "Sisacko-moslavacka zupanija",
                  "index": "514"
              },
              {
                  "code": "HR-04",
                  "name": "Karlovacka zupanija",
                  "index": "515"
              },
              {
                  "code": "HR-05",
                  "name": "Varazdinska zupanija",
                  "index": "516"
              },
              {
                  "code": "HR-06",
                  "name": "Koprivnicko-krizevacka zupanija",
                  "index": "517"
              },
              {
                  "code": "HR-07",
                  "name": "Bjelovarsko-bilogorska zupanija",
                  "index": "518"
              },
              {
                  "code": "HR-08",
                  "name": "Primorsko-goranska zupanija",
                  "index": "519"
              },
              {
                  "code": "HR-09",
                  "name": "Licko-senjska zupanija",
                  "index": "520"
              },
              {
                  "code": "HR-10",
                  "name": "Viroviticko-podravska zupanija",
                  "index": "521"
              },
              {
                  "code": "HR-11",
                  "name": "Pozesko-slavonska zupanija",
                  "index": "522"
              },
              {
                  "code": "HR-12",
                  "name": "Brodsko-posavska zupanija",
                  "index": "523"
              },
              {
                  "code": "HR-13",
                  "name": "Zadarska zupanija",
                  "index": "524"
              },
              {
                  "code": "HR-14",
                  "name": "Osjecko-baranjska zupanija",
                  "index": "525"
              },
              {
                  "code": "HR-15",
                  "name": "Sibensko-kninska zupanija",
                  "index": "526"
              },
              {
                  "code": "HR-16",
                  "name": "Vukovarsko-srijemska zupanija",
                  "index": "527"
              },
              {
                  "code": "HR-17",
                  "name": "Splitsko-dalmatinska zupanija",
                  "index": "528"
              },
              {
                  "code": "HR-18",
                  "name": "Istarska zupanija",
                  "index": "529"
              },
              {
                  "code": "HR-19",
                  "name": "Dubrovacko-neretvanska zupanija",
                  "index": "530"
              },
              {
                  "code": "HR-20",
                  "name": "Medimurska zupanija",
                  "index": "531"
              },
              {
                  "code": "HR-21",
                  "name": "Grad Zagreb",
                  "index": "532"
              }
          ]
      },
      "HT": {
          "name": "Haiti",
          "code": "HT"
      },
      "HU": {
          "name": "Hungary",
          "code": "HU"
      },
      "ID": {
          "name": "Indonesia",
          "code": "ID"
      },
      "IE": {
          "name": "Ireland",
          "code": "IE"
      },
      "IL": {
          "name": "Israel",
          "code": "IL"
      },
      "IM": {
          "name": "Isle of Man",
          "code": "IM"
      },
      "IN": {
          "name": "India",
          "code": "IN",
          "regions": [
              {
                  "code": "AN",
                  "name": "Andaman and Nicobar Islands",
                  "index": "533"
              },
              {
                  "code": "AP",
                  "name": "Andhra Pradesh",
                  "index": "534"
              },
              {
                  "code": "AR",
                  "name": "Arunachal Pradesh",
                  "index": "535"
              },
              {
                  "code": "AS",
                  "name": "Assam",
                  "index": "536"
              },
              {
                  "code": "BR",
                  "name": "Bihar",
                  "index": "537"
              },
              {
                  "code": "CH",
                  "name": "Chandigarh",
                  "index": "538"
              },
              {
                  "code": "CT",
                  "name": "Chhattisgarh",
                  "index": "539"
              },
              {
                  "code": "DN",
                  "name": "Dadra and Nagar Haveli",
                  "index": "540"
              },
              {
                  "code": "DD",
                  "name": "Daman and Diu",
                  "index": "541"
              },
              {
                  "code": "DL",
                  "name": "Delhi",
                  "index": "542"
              },
              {
                  "code": "GA",
                  "name": "Goa",
                  "index": "543"
              },
              {
                  "code": "GJ",
                  "name": "Gujarat",
                  "index": "544"
              },
              {
                  "code": "HR",
                  "name": "Haryana",
                  "index": "545"
              },
              {
                  "code": "HP",
                  "name": "Himachal Pradesh",
                  "index": "546"
              },
              {
                  "code": "JK",
                  "name": "Jammu and Kashmir",
                  "index": "547"
              },
              {
                  "code": "JH",
                  "name": "Jharkhand",
                  "index": "548"
              },
              {
                  "code": "KA",
                  "name": "Karnataka",
                  "index": "549"
              },
              {
                  "code": "KL",
                  "name": "Kerala",
                  "index": "550"
              },
              {
                  "code": "LD",
                  "name": "Lakshadweep",
                  "index": "551"
              },
              {
                  "code": "MP",
                  "name": "Madhya Pradesh",
                  "index": "552"
              },
              {
                  "code": "MH",
                  "name": "Maharashtra",
                  "index": "553"
              },
              {
                  "code": "MN",
                  "name": "Manipur",
                  "index": "554"
              },
              {
                  "code": "ML",
                  "name": "Meghalaya",
                  "index": "555"
              },
              {
                  "code": "MZ",
                  "name": "Mizoram",
                  "index": "556"
              },
              {
                  "code": "NL",
                  "name": "Nagaland",
                  "index": "557"
              },
              {
                  "code": "OR",
                  "name": "Odisha",
                  "index": "558"
              },
              {
                  "code": "PY",
                  "name": "Puducherry",
                  "index": "559"
              },
              {
                  "code": "PB",
                  "name": "Punjab",
                  "index": "560"
              },
              {
                  "code": "RJ",
                  "name": "Rajasthan",
                  "index": "561"
              },
              {
                  "code": "SK",
                  "name": "Sikkim",
                  "index": "562"
              },
              {
                  "code": "TN",
                  "name": "Tamil Nadu",
                  "index": "563"
              },
              {
                  "code": "TG",
                  "name": "Telangana",
                  "index": "564"
              },
              {
                  "code": "TR",
                  "name": "Tripura",
                  "index": "565"
              },
              {
                  "code": "UP",
                  "name": "Uttar Pradesh",
                  "index": "566"
              },
              {
                  "code": "UT",
                  "name": "Uttarakhand",
                  "index": "567"
              },
              {
                  "code": "WB",
                  "name": "West Bengal",
                  "index": "568"
              },
              {
                  "code": "AN",
                  "name": "Andaman and Nicobar Islands",
                  "index": "569"
              },
              {
                  "code": "AP",
                  "name": "Andhra Pradesh",
                  "index": "570"
              },
              {
                  "code": "AR",
                  "name": "Arunachal Pradesh",
                  "index": "571"
              },
              {
                  "code": "AS",
                  "name": "Assam",
                  "index": "572"
              },
              {
                  "code": "BR",
                  "name": "Bihar",
                  "index": "573"
              },
              {
                  "code": "CH",
                  "name": "Chandigarh",
                  "index": "574"
              },
              {
                  "code": "CT",
                  "name": "Chhattisgarh",
                  "index": "575"
              },
              {
                  "code": "DN",
                  "name": "Dadra and Nagar Haveli",
                  "index": "576"
              },
              {
                  "code": "DD",
                  "name": "Daman and Diu",
                  "index": "577"
              },
              {
                  "code": "DL",
                  "name": "Delhi",
                  "index": "578"
              },
              {
                  "code": "GA",
                  "name": "Goa",
                  "index": "579"
              },
              {
                  "code": "GJ",
                  "name": "Gujarat",
                  "index": "580"
              },
              {
                  "code": "HR",
                  "name": "Haryana",
                  "index": "581"
              },
              {
                  "code": "HP",
                  "name": "Himachal Pradesh",
                  "index": "582"
              },
              {
                  "code": "JK",
                  "name": "Jammu and Kashmir",
                  "index": "583"
              },
              {
                  "code": "JH",
                  "name": "Jharkhand",
                  "index": "584"
              },
              {
                  "code": "KA",
                  "name": "Karnataka",
                  "index": "585"
              },
              {
                  "code": "KL",
                  "name": "Kerala",
                  "index": "586"
              },
              {
                  "code": "LD",
                  "name": "Lakshadweep",
                  "index": "587"
              },
              {
                  "code": "MP",
                  "name": "Madhya Pradesh",
                  "index": "588"
              },
              {
                  "code": "MH",
                  "name": "Maharashtra",
                  "index": "589"
              },
              {
                  "code": "MN",
                  "name": "Manipur",
                  "index": "590"
              },
              {
                  "code": "ML",
                  "name": "Meghalaya",
                  "index": "591"
              },
              {
                  "code": "MZ",
                  "name": "Mizoram",
                  "index": "592"
              },
              {
                  "code": "NL",
                  "name": "Nagaland",
                  "index": "593"
              },
              {
                  "code": "OR",
                  "name": "Odisha",
                  "index": "594"
              },
              {
                  "code": "PY",
                  "name": "Puducherry",
                  "index": "595"
              },
              {
                  "code": "PB",
                  "name": "Punjab",
                  "index": "596"
              },
              {
                  "code": "RJ",
                  "name": "Rajasthan",
                  "index": "597"
              },
              {
                  "code": "SK",
                  "name": "Sikkim",
                  "index": "598"
              },
              {
                  "code": "TN",
                  "name": "Tamil Nadu",
                  "index": "599"
              },
              {
                  "code": "TG",
                  "name": "Telangana",
                  "index": "600"
              },
              {
                  "code": "TR",
                  "name": "Tripura",
                  "index": "601"
              },
              {
                  "code": "UP",
                  "name": "Uttar Pradesh",
                  "index": "602"
              },
              {
                  "code": "UT",
                  "name": "Uttarakhand",
                  "index": "603"
              },
              {
                  "code": "WB",
                  "name": "West Bengal",
                  "index": "604"
              }
          ]
      },
      "IO": {
          "name": "British Indian Ocean Territory",
          "code": "IO"
      },
      "IQ": {
          "name": "Iraq",
          "code": "IQ"
      },
      "IR": {
          "name": "Iran",
          "code": "IR"
      },
      "IS": {
          "name": "Iceland",
          "code": "IS"
      },
      "IT": {
          "name": "Italy",
          "code": "IT"
      },
      "JE": {
          "name": "Jersey",
          "code": "JE"
      },
      "JM": {
          "name": "Jamaica",
          "code": "JM"
      },
      "JO": {
          "name": "Jordan",
          "code": "JO"
      },
      "JP": {
          "name": "Japan",
          "code": "JP"
      },
      "KE": {
          "name": "Kenya",
          "code": "KE"
      },
      "KG": {
          "name": "Kyrgyzstan",
          "code": "KG"
      },
      "KH": {
          "name": "Cambodia",
          "code": "KH"
      },
      "KI": {
          "name": "Kiribati",
          "code": "KI"
      },
      "KM": {
          "name": "Comoros",
          "code": "KM"
      },
      "KN": {
          "name": "St. Kitts & Nevis",
          "code": "KN"
      },
      "KP": {
          "name": "North Korea",
          "code": "KP"
      },
      "KR": {
          "name": "South Korea",
          "code": "KR"
      },
      "KW": {
          "name": "Kuwait",
          "code": "KW"
      },
      "KY": {
          "name": "Cayman Islands",
          "code": "KY"
      },
      "KZ": {
          "name": "Kazakhstan",
          "code": "KZ"
      },
      "LA": {
          "name": "Laos",
          "code": "LA"
      },
      "LB": {
          "name": "Lebanon",
          "code": "LB"
      },
      "LC": {
          "name": "St. Lucia",
          "code": "LC"
      },
      "LI": {
          "name": "Liechtenstein",
          "code": "LI"
      },
      "LK": {
          "name": "Sri Lanka",
          "code": "LK"
      },
      "LR": {
          "name": "Liberia",
          "code": "LR"
      },
      "LS": {
          "name": "Lesotho",
          "code": "LS"
      },
      "LT": {
          "name": "Lithuania",
          "code": "LT",
          "regions": [
              {
                  "code": "LT-AL",
                  "name": "Alytaus Apskritis",
                  "index": "475"
              },
              {
                  "code": "LT-KU",
                  "name": "Kauno Apskritis",
                  "index": "476"
              },
              {
                  "code": "LT-KL",
                  "name": "Klaipedos Apskritis",
                  "index": "477"
              },
              {
                  "code": "LT-MR",
                  "name": "Marijampoles Apskritis",
                  "index": "478"
              },
              {
                  "code": "LT-PN",
                  "name": "Panevezio Apskritis",
                  "index": "479"
              },
              {
                  "code": "LT-SA",
                  "name": "Siauliu Apskritis",
                  "index": "480"
              },
              {
                  "code": "LT-TA",
                  "name": "Taurages Apskritis",
                  "index": "481"
              },
              {
                  "code": "LT-TE",
                  "name": "Telsiu Apskritis",
                  "index": "482"
              },
              {
                  "code": "LT-UT",
                  "name": "Utenos Apskritis",
                  "index": "483"
              },
              {
                  "code": "LT-VL",
                  "name": "Vilniaus Apskritis",
                  "index": "484"
              }
          ]
      },
      "LU": {
          "name": "Luxembourg",
          "code": "LU"
      },
      "LV": {
          "name": "Latvia",
          "code": "LV",
          "regions": [
              {
                  "code": "LV-DGV",
                  "name": "Daugavpils",
                  "index": "355"
              },
              {
                  "code": "LV-JEL",
                  "name": "Jelgava",
                  "index": "356"
              },
              {
                  "code": "Jēkabpils",
                  "name": "Jekabpils",
                  "index": "357"
              },
              {
                  "code": "LV-JUR",
                  "name": "Jurmala",
                  "index": "358"
              },
              {
                  "code": "LV-LPX",
                  "name": "Liepaja",
                  "index": "359"
              },
              {
                  "code": "LV-LE",
                  "name": "Liepajas novads",
                  "index": "360"
              },
              {
                  "code": "LV-REZ",
                  "name": "Rezekne",
                  "index": "361"
              },
              {
                  "code": "LV-RIX",
                  "name": "Riga",
                  "index": "362"
              },
              {
                  "code": "LV-RI",
                  "name": "Rigas novads",
                  "index": "363"
              },
              {
                  "code": "Valmiera",
                  "name": "Valmiera",
                  "index": "364"
              },
              {
                  "code": "LV-VEN",
                  "name": "Ventspils",
                  "index": "365"
              },
              {
                  "code": "Aglonas novads",
                  "name": "Aglonas novads",
                  "index": "366"
              },
              {
                  "code": "LV-AI",
                  "name": "Aizkraukles novads",
                  "index": "367"
              },
              {
                  "code": "Aizputes novads",
                  "name": "Aizputes novads",
                  "index": "368"
              },
              {
                  "code": "Aknīstes novads",
                  "name": "Aknistes novads",
                  "index": "369"
              },
              {
                  "code": "Alojas novads",
                  "name": "Alojas novads",
                  "index": "370"
              },
              {
                  "code": "Alsungas novads",
                  "name": "Alsungas novads",
                  "index": "371"
              },
              {
                  "code": "LV-AL",
                  "name": "Aluksnes novads",
                  "index": "372"
              },
              {
                  "code": "Amatas novads",
                  "name": "Amatas novads",
                  "index": "373"
              },
              {
                  "code": "Apes novads",
                  "name": "Apes novads",
                  "index": "374"
              },
              {
                  "code": "Auces novads",
                  "name": "Auces novads",
                  "index": "375"
              },
              {
                  "code": "Babītes novads",
                  "name": "Babites novads",
                  "index": "376"
              },
              {
                  "code": "Baldones novads",
                  "name": "Baldones novads",
                  "index": "377"
              },
              {
                  "code": "Baltinavas novads",
                  "name": "Baltinavas novads",
                  "index": "378"
              },
              {
                  "code": "LV-BL",
                  "name": "Balvu novads",
                  "index": "379"
              },
              {
                  "code": "LV-BU",
                  "name": "Bauskas novads",
                  "index": "380"
              },
              {
                  "code": "Beverīnas novads",
                  "name": "Beverinas novads",
                  "index": "381"
              },
              {
                  "code": "Brocēnu novads",
                  "name": "Brocenu novads",
                  "index": "382"
              },
              {
                  "code": "Burtnieku novads",
                  "name": "Burtnieku novads",
                  "index": "383"
              },
              {
                  "code": "Carnikavas novads",
                  "name": "Carnikavas novads",
                  "index": "384"
              },
              {
                  "code": "Cesvaines novads",
                  "name": "Cesvaines novads",
                  "index": "385"
              },
              {
                  "code": "Ciblas novads",
                  "name": "Ciblas novads",
                  "index": "386"
              },
              {
                  "code": "LV-CE",
                  "name": "Cesu novads",
                  "index": "387"
              },
              {
                  "code": "Dagdas novads",
                  "name": "Dagdas novads",
                  "index": "388"
              },
              {
                  "code": "LV-DA",
                  "name": "Daugavpils novads",
                  "index": "389"
              },
              {
                  "code": "LV-DO",
                  "name": "Dobeles novads",
                  "index": "390"
              },
              {
                  "code": "Dundagas novads",
                  "name": "Dundagas novads",
                  "index": "391"
              },
              {
                  "code": "Durbes novads",
                  "name": "Durbes novads",
                  "index": "392"
              },
              {
                  "code": "Engures novads",
                  "name": "Engures novads",
                  "index": "393"
              },
              {
                  "code": "Garkalnes novads",
                  "name": "Garkalnes novads",
                  "index": "394"
              },
              {
                  "code": "Grobiņas novads",
                  "name": "Grobinas novads",
                  "index": "395"
              },
              {
                  "code": "LV-GU",
                  "name": "Gulbenes novads",
                  "index": "396"
              },
              {
                  "code": "Iecavas novads",
                  "name": "Iecavas novads",
                  "index": "397"
              },
              {
                  "code": "Ikšķiles novads",
                  "name": "Ikskiles novads",
                  "index": "398"
              },
              {
                  "code": "Ilūkstes novads",
                  "name": "Ilukstes novads",
                  "index": "399"
              },
              {
                  "code": "Inčukalna novads",
                  "name": "Incukalna novads",
                  "index": "400"
              },
              {
                  "code": "Jaunjelgavas novads",
                  "name": "Jaunjelgavas novads",
                  "index": "401"
              },
              {
                  "code": "Jaunpiebalgas novads",
                  "name": "Jaunpiebalgas novads",
                  "index": "402"
              },
              {
                  "code": "Jaunpils novads",
                  "name": "Jaunpils novads",
                  "index": "403"
              },
              {
                  "code": "LV-JL",
                  "name": "Jelgavas novads",
                  "index": "404"
              },
              {
                  "code": "LV-JK",
                  "name": "Jekabpils novads",
                  "index": "405"
              },
              {
                  "code": "Kandavas novads",
                  "name": "Kandavas novads",
                  "index": "406"
              },
              {
                  "code": "Kokneses novads",
                  "name": "Kokneses novads",
                  "index": "407"
              },
              {
                  "code": "Krimuldas novads",
                  "name": "Krimuldas novads",
                  "index": "408"
              },
              {
                  "code": "Krustpils novads",
                  "name": "Krustpils novads",
                  "index": "409"
              },
              {
                  "code": "LV-KR",
                  "name": "Kraslavas novads",
                  "index": "410"
              },
              {
                  "code": "LV-KU",
                  "name": "Kuldigas novads",
                  "index": "411"
              },
              {
                  "code": "Kārsavas novads",
                  "name": "Karsavas novads",
                  "index": "412"
              },
              {
                  "code": "Lielvārdes novads",
                  "name": "Lielvardes novads",
                  "index": "413"
              },
              {
                  "code": "LV-LM",
                  "name": "Limbazu novads",
                  "index": "414"
              },
              {
                  "code": "Lubānas novads",
                  "name": "Lubanas novads",
                  "index": "415"
              },
              {
                  "code": "LV-LU",
                  "name": "Ludzas novads",
                  "index": "416"
              },
              {
                  "code": "Līgatnes novads",
                  "name": "Ligatnes novads",
                  "index": "417"
              },
              {
                  "code": "Līvānu novads",
                  "name": "Livanu novads",
                  "index": "418"
              },
              {
                  "code": "LV-MA",
                  "name": "Madonas novads",
                  "index": "419"
              },
              {
                  "code": "Mazsalacas novads",
                  "name": "Mazsalacas novads",
                  "index": "420"
              },
              {
                  "code": "Mālpils novads",
                  "name": "Malpils novads",
                  "index": "421"
              },
              {
                  "code": "Mārupes novads",
                  "name": "Marupes novads",
                  "index": "422"
              },
              {
                  "code": "Naukšēnu novads",
                  "name": "Nauksenu novads",
                  "index": "423"
              },
              {
                  "code": "Neretas novads",
                  "name": "Neretas novads",
                  "index": "424"
              },
              {
                  "code": "Nīcas novads",
                  "name": "Nicas novads",
                  "index": "425"
              },
              {
                  "code": "LV-OG",
                  "name": "Ogres novads",
                  "index": "426"
              },
              {
                  "code": "Olaines novads",
                  "name": "Olaines novads",
                  "index": "427"
              },
              {
                  "code": "Ozolnieku novads",
                  "name": "Ozolnieku novads",
                  "index": "428"
              },
              {
                  "code": "LV-PR",
                  "name": "Preilu novads",
                  "index": "429"
              },
              {
                  "code": "Priekules novads",
                  "name": "Priekules novads",
                  "index": "430"
              },
              {
                  "code": "Priekuļu novads",
                  "name": "Priekulu novads",
                  "index": "431"
              },
              {
                  "code": "Pārgaujas novads",
                  "name": "Pargaujas novads",
                  "index": "432"
              },
              {
                  "code": "Pāvilostas novads",
                  "name": "Pavilostas novads",
                  "index": "433"
              },
              {
                  "code": "Pļaviņu novads",
                  "name": "Plavinu novads",
                  "index": "434"
              },
              {
                  "code": "Raunas novads",
                  "name": "Raunas novads",
                  "index": "435"
              },
              {
                  "code": "Riebiņu novads",
                  "name": "Riebinu novads",
                  "index": "436"
              },
              {
                  "code": "Rojas novads",
                  "name": "Rojas novads",
                  "index": "437"
              },
              {
                  "code": "Ropažu novads",
                  "name": "Ropazu novads",
                  "index": "438"
              },
              {
                  "code": "Rucavas novads",
                  "name": "Rucavas novads",
                  "index": "439"
              },
              {
                  "code": "Rugāju novads",
                  "name": "Rugaju novads",
                  "index": "440"
              },
              {
                  "code": "Rundāles novads",
                  "name": "Rundales novads",
                  "index": "441"
              },
              {
                  "code": "LV-RE",
                  "name": "Rezeknes novads",
                  "index": "442"
              },
              {
                  "code": "Rūjienas novads",
                  "name": "Rujienas novads",
                  "index": "443"
              },
              {
                  "code": "Salacgrīvas novads",
                  "name": "Salacgrivas novads",
                  "index": "444"
              },
              {
                  "code": "Salas novads",
                  "name": "Salas novads",
                  "index": "445"
              },
              {
                  "code": "Salaspils novads",
                  "name": "Salaspils novads",
                  "index": "446"
              },
              {
                  "code": "LV-SA",
                  "name": "Saldus novads",
                  "index": "447"
              },
              {
                  "code": "Saulkrastu novads",
                  "name": "Saulkrastu novads",
                  "index": "448"
              },
              {
                  "code": "Siguldas novads",
                  "name": "Siguldas novads",
                  "index": "449"
              },
              {
                  "code": "Skrundas novads",
                  "name": "Skrundas novads",
                  "index": "450"
              },
              {
                  "code": "Skrīveru novads",
                  "name": "Skriveru novads",
                  "index": "451"
              },
              {
                  "code": "Smiltenes novads",
                  "name": "Smiltenes novads",
                  "index": "452"
              },
              {
                  "code": "Stopiņu novads",
                  "name": "Stopinu novads",
                  "index": "453"
              },
              {
                  "code": "Strenču novads",
                  "name": "Strencu novads",
                  "index": "454"
              },
              {
                  "code": "Sējas novads",
                  "name": "Sejas novads",
                  "index": "455"
              },
              {
                  "code": "LV-TA",
                  "name": "Talsu novads",
                  "index": "456"
              },
              {
                  "code": "LV-TU",
                  "name": "Tukuma novads",
                  "index": "457"
              },
              {
                  "code": "Tērvetes novads",
                  "name": "Tervetes novads",
                  "index": "458"
              },
              {
                  "code": "Vaiņodes novads",
                  "name": "Vainodes novads",
                  "index": "459"
              },
              {
                  "code": "LV-VK",
                  "name": "Valkas novads",
                  "index": "460"
              },
              {
                  "code": "LV-VM",
                  "name": "Valmieras novads",
                  "index": "461"
              },
              {
                  "code": "Varakļānu novads",
                  "name": "Varaklanu novads",
                  "index": "462"
              },
              {
                  "code": "Vecpiebalgas novads",
                  "name": "Vecpiebalgas novads",
                  "index": "463"
              },
              {
                  "code": "Vecumnieku novads",
                  "name": "Vecumnieku novads",
                  "index": "464"
              },
              {
                  "code": "LV-VE",
                  "name": "Ventspils novads",
                  "index": "465"
              },
              {
                  "code": "Viesītes novads",
                  "name": "Viesites novads",
                  "index": "466"
              },
              {
                  "code": "Viļakas novads",
                  "name": "Vilakas novads",
                  "index": "467"
              },
              {
                  "code": "Viļānu novads",
                  "name": "Vilanu novads",
                  "index": "468"
              },
              {
                  "code": "Vārkavas novads",
                  "name": "Varkavas novads",
                  "index": "469"
              },
              {
                  "code": "Zilupes novads",
                  "name": "Zilupes novads",
                  "index": "470"
              },
              {
                  "code": "Ādažu novads",
                  "name": "Adazu novads",
                  "index": "471"
              },
              {
                  "code": "Ērgļu novads",
                  "name": "Erglu novads",
                  "index": "472"
              },
              {
                  "code": "Ķeguma novads",
                  "name": "Keguma novads",
                  "index": "473"
              },
              {
                  "code": "Ķekavas novads",
                  "name": "Kekavas novads",
                  "index": "474"
              }
          ]
      },
      "LY": {
          "name": "Libya",
          "code": "LY"
      },
      "MA": {
          "name": "Morocco",
          "code": "MA"
      },
      "MC": {
          "name": "Monaco",
          "code": "MC"
      },
      "MD": {
          "name": "Moldova",
          "code": "MD"
      },
      "ME": {
          "name": "Montenegro",
          "code": "ME"
      },
      "MF": {
          "name": "St. Martin",
          "code": "MF"
      },
      "MG": {
          "name": "Madagascar",
          "code": "MG"
      },
      "MH": {
          "name": "Marshall Islands",
          "code": "MH"
      },
      "MK": {
          "name": "North Macedonia",
          "code": "MK"
      },
      "ML": {
          "name": "Mali",
          "code": "ML"
      },
      "MM": {
          "name": "Myanmar (Burma)",
          "code": "MM"
      },
      "MN": {
          "name": "Mongolia",
          "code": "MN"
      },
      "MO": {
          "name": "Macao SAR China",
          "code": "MO"
      },
      "MP": {
          "name": "Northern Mariana Islands",
          "code": "MP"
      },
      "MQ": {
          "name": "Martinique",
          "code": "MQ"
      },
      "MR": {
          "name": "Mauritania",
          "code": "MR"
      },
      "MS": {
          "name": "Montserrat",
          "code": "MS"
      },
      "MT": {
          "name": "Malta",
          "code": "MT"
      },
      "MU": {
          "name": "Mauritius",
          "code": "MU"
      },
      "MV": {
          "name": "Maldives",
          "code": "MV"
      },
      "MW": {
          "name": "Malawi",
          "code": "MW"
      },
      "MX": {
          "name": "Mexico",
          "code": "MX"
      },
      "MY": {
          "name": "Malaysia",
          "code": "MY"
      },
      "MZ": {
          "name": "Mozambique",
          "code": "MZ"
      },
      "NA": {
          "name": "Namibia",
          "code": "NA"
      },
      "NC": {
          "name": "New Caledonia",
          "code": "NC"
      },
      "NE": {
          "name": "Niger",
          "code": "NE"
      },
      "NF": {
          "name": "Norfolk Island",
          "code": "NF"
      },
      "NG": {
          "name": "Nigeria",
          "code": "NG"
      },
      "NI": {
          "name": "Nicaragua",
          "code": "NI"
      },
      "NL": {
          "name": "Netherlands",
          "code": "NL"
      },
      "NO": {
          "name": "Norway",
          "code": "NO"
      },
      "NP": {
          "name": "Nepal",
          "code": "NP"
      },
      "NR": {
          "name": "Nauru",
          "code": "NR"
      },
      "NU": {
          "name": "Niue",
          "code": "NU"
      },
      "NZ": {
          "name": "New Zealand",
          "code": "NZ"
      },
      "OM": {
          "name": "Oman",
          "code": "OM"
      },
      "PA": {
          "name": "Panama",
          "code": "PA"
      },
      "PE": {
          "name": "Peru",
          "code": "PE"
      },
      "PF": {
          "name": "French Polynesia",
          "code": "PF"
      },
      "PG": {
          "name": "Papua New Guinea",
          "code": "PG"
      },
      "PH": {
          "name": "Philippines",
          "code": "PH"
      },
      "PK": {
          "name": "Pakistan",
          "code": "PK"
      },
      "PL": {
          "name": "Poland",
          "code": "PL"
      },
      "PM": {
          "name": "St. Pierre & Miquelon",
          "code": "PM"
      },
      "PN": {
          "name": "Pitcairn Islands",
          "code": "PN"
      },
      "PS": {
          "name": "Palestinian Territories",
          "code": "PS"
      },
      "PT": {
          "name": "Portugal",
          "code": "PT"
      },
      "PW": {
          "name": "Palau",
          "code": "PW"
      },
      "PY": {
          "name": "Paraguay",
          "code": "PY"
      },
      "QA": {
          "name": "Qatar",
          "code": "QA"
      },
      "RE": {
          "name": "Réunion",
          "code": "RE"
      },
      "RO": {
          "name": "Romania",
          "code": "RO",
          "regions": [
              {
                  "code": "AB",
                  "name": "Alba",
                  "index": "278"
              },
              {
                  "code": "AR",
                  "name": "Arad",
                  "index": "279"
              },
              {
                  "code": "AG",
                  "name": "Arges",
                  "index": "280"
              },
              {
                  "code": "BC",
                  "name": "Bacau",
                  "index": "281"
              },
              {
                  "code": "BH",
                  "name": "Bihor",
                  "index": "282"
              },
              {
                  "code": "BN",
                  "name": "Bistrita-Nasaud",
                  "index": "283"
              },
              {
                  "code": "BT",
                  "name": "Botosani",
                  "index": "284"
              },
              {
                  "code": "BV",
                  "name": "Brasov",
                  "index": "285"
              },
              {
                  "code": "BR",
                  "name": "Braila",
                  "index": "286"
              },
              {
                  "code": "B",
                  "name": "Bucuresti",
                  "index": "287"
              },
              {
                  "code": "BZ",
                  "name": "Buzau",
                  "index": "288"
              },
              {
                  "code": "CS",
                  "name": "Caras-Severin",
                  "index": "289"
              },
              {
                  "code": "CL",
                  "name": "Calarasi",
                  "index": "290"
              },
              {
                  "code": "CJ",
                  "name": "Cluj",
                  "index": "291"
              },
              {
                  "code": "CT",
                  "name": "Constanta",
                  "index": "292"
              },
              {
                  "code": "CV",
                  "name": "Covasna",
                  "index": "293"
              },
              {
                  "code": "DB",
                  "name": "Dambovita",
                  "index": "294"
              },
              {
                  "code": "DJ",
                  "name": "Dolj",
                  "index": "295"
              },
              {
                  "code": "GL",
                  "name": "Galati",
                  "index": "296"
              },
              {
                  "code": "GR",
                  "name": "Giurgiu",
                  "index": "297"
              },
              {
                  "code": "GJ",
                  "name": "Gorj",
                  "index": "298"
              },
              {
                  "code": "HR",
                  "name": "Harghita",
                  "index": "299"
              },
              {
                  "code": "HD",
                  "name": "Hunedoara",
                  "index": "300"
              },
              {
                  "code": "IL",
                  "name": "Ialomita",
                  "index": "301"
              },
              {
                  "code": "IS",
                  "name": "Iasi",
                  "index": "302"
              },
              {
                  "code": "IF",
                  "name": "Ilfov",
                  "index": "303"
              },
              {
                  "code": "MM",
                  "name": "Maramures",
                  "index": "304"
              },
              {
                  "code": "MH",
                  "name": "Mehedinti",
                  "index": "305"
              },
              {
                  "code": "MS",
                  "name": "Mures",
                  "index": "306"
              },
              {
                  "code": "NT",
                  "name": "Neamt",
                  "index": "307"
              },
              {
                  "code": "OT",
                  "name": "Olt",
                  "index": "308"
              },
              {
                  "code": "PH",
                  "name": "Prahova",
                  "index": "309"
              },
              {
                  "code": "SM",
                  "name": "Satu-Mare",
                  "index": "310"
              },
              {
                  "code": "SJ",
                  "name": "Salaj",
                  "index": "311"
              },
              {
                  "code": "SB",
                  "name": "Sibiu",
                  "index": "312"
              },
              {
                  "code": "SV",
                  "name": "Suceava",
                  "index": "313"
              },
              {
                  "code": "TR",
                  "name": "Teleorman",
                  "index": "314"
              },
              {
                  "code": "TM",
                  "name": "Timis",
                  "index": "315"
              },
              {
                  "code": "TL",
                  "name": "Tulcea",
                  "index": "316"
              },
              {
                  "code": "VS",
                  "name": "Vaslui",
                  "index": "317"
              },
              {
                  "code": "VL",
                  "name": "Valcea",
                  "index": "318"
              },
              {
                  "code": "VN",
                  "name": "Vrancea",
                  "index": "319"
              }
          ]
      },
      "RS": {
          "name": "Serbia",
          "code": "RS"
      },
      "RU": {
          "name": "Russia",
          "code": "RU"
      },
      "RW": {
          "name": "Rwanda",
          "code": "RW"
      },
      "SA": {
          "name": "Saudi Arabia",
          "code": "SA"
      },
      "SB": {
          "name": "Solomon Islands",
          "code": "SB"
      },
      "SC": {
          "name": "Seychelles",
          "code": "SC"
      },
      "SD": {
          "name": "Sudan",
          "code": "SD"
      },
      "SE": {
          "name": "Sweden",
          "code": "SE"
      },
      "SG": {
          "name": "Singapore",
          "code": "SG"
      },
      "SH": {
          "name": "St. Helena",
          "code": "SH"
      },
      "SI": {
          "name": "Slovenia",
          "code": "SI"
      },
      "SJ": {
          "name": "Svalbard & Jan Mayen",
          "code": "SJ"
      },
      "SK": {
          "name": "Slovakia",
          "code": "SK"
      },
      "SL": {
          "name": "Sierra Leone",
          "code": "SL"
      },
      "SM": {
          "name": "San Marino",
          "code": "SM"
      },
      "SN": {
          "name": "Senegal",
          "code": "SN"
      },
      "SO": {
          "name": "Somalia",
          "code": "SO"
      },
      "SR": {
          "name": "Suriname",
          "code": "SR"
      },
      "ST": {
          "name": "São Tomé & Príncipe",
          "code": "ST"
      },
      "SV": {
          "name": "El Salvador",
          "code": "SV"
      },
      "SY": {
          "name": "Syria",
          "code": "SY"
      },
      "SZ": {
          "name": "Eswatini",
          "code": "SZ"
      },
      "TC": {
          "name": "Turks & Caicos Islands",
          "code": "TC"
      },
      "TD": {
          "name": "Chad",
          "code": "TD"
      },
      "TF": {
          "name": "French Southern Territories",
          "code": "TF"
      },
      "TG": {
          "name": "Togo",
          "code": "TG"
      },
      "TH": {
          "name": "Thailand",
          "code": "TH"
      },
      "TJ": {
          "name": "Tajikistan",
          "code": "TJ"
      },
      "TK": {
          "name": "Tokelau",
          "code": "TK"
      },
      "TL": {
          "name": "Timor-Leste",
          "code": "TL"
      },
      "TM": {
          "name": "Turkmenistan",
          "code": "TM"
      },
      "TN": {
          "name": "Tunisia",
          "code": "TN"
      },
      "TO": {
          "name": "Tonga",
          "code": "TO"
      },
      "TR": {
          "name": "Turkey",
          "code": "TR"
      },
      "TT": {
          "name": "Trinidad & Tobago",
          "code": "TT"
      },
      "TV": {
          "name": "Tuvalu",
          "code": "TV"
      },
      "TW": {
          "name": "Taiwan",
          "code": "TW"
      },
      "TZ": {
          "name": "Tanzania",
          "code": "TZ"
      },
      "UA": {
          "name": "Ukraine",
          "code": "UA"
      },
      "UG": {
          "name": "Uganda",
          "code": "UG"
      },
      "UM": {
          "name": "U.S. Outlying Islands",
          "code": "UM"
      },
      "US": {
          "name": "United States",
          "code": "US",
          "regions": [
              {
                  "code": "AL",
                  "name": "Alabama",
                  "index": "1"
              },
              {
                  "code": "AK",
                  "name": "Alaska",
                  "index": "2"
              },
              {
                  "code": "AS",
                  "name": "American Samoa",
                  "index": "3"
              },
              {
                  "code": "AZ",
                  "name": "Arizona",
                  "index": "4"
              },
              {
                  "code": "AR",
                  "name": "Arkansas",
                  "index": "5"
              },
              {
                  "code": "AE",
                  "name": "Armed Forces Africa",
                  "index": "6"
              },
              {
                  "code": "AA",
                  "name": "Armed Forces Americas",
                  "index": "7"
              },
              {
                  "code": "AE",
                  "name": "Armed Forces Canada",
                  "index": "8"
              },
              {
                  "code": "AE",
                  "name": "Armed Forces Europe",
                  "index": "9"
              },
              {
                  "code": "AE",
                  "name": "Armed Forces Middle East",
                  "index": "10"
              },
              {
                  "code": "AP",
                  "name": "Armed Forces Pacific",
                  "index": "11"
              },
              {
                  "code": "CA",
                  "name": "California",
                  "index": "12"
              },
              {
                  "code": "CO",
                  "name": "Colorado",
                  "index": "13"
              },
              {
                  "code": "CT",
                  "name": "Connecticut",
                  "index": "14"
              },
              {
                  "code": "DE",
                  "name": "Delaware",
                  "index": "15"
              },
              {
                  "code": "DC",
                  "name": "District of Columbia",
                  "index": "16"
              },
              {
                  "code": "FM",
                  "name": "Federated States Of Micronesia",
                  "index": "17"
              },
              {
                  "code": "FL",
                  "name": "Florida",
                  "index": "18"
              },
              {
                  "code": "GA",
                  "name": "Georgia",
                  "index": "19"
              },
              {
                  "code": "GU",
                  "name": "Guam",
                  "index": "20"
              },
              {
                  "code": "HI",
                  "name": "Hawaii",
                  "index": "21"
              },
              {
                  "code": "ID",
                  "name": "Idaho",
                  "index": "22"
              },
              {
                  "code": "IL",
                  "name": "Illinois",
                  "index": "23"
              },
              {
                  "code": "IN",
                  "name": "Indiana",
                  "index": "24"
              },
              {
                  "code": "IA",
                  "name": "Iowa",
                  "index": "25"
              },
              {
                  "code": "KS",
                  "name": "Kansas",
                  "index": "26"
              },
              {
                  "code": "KY",
                  "name": "Kentucky",
                  "index": "27"
              },
              {
                  "code": "LA",
                  "name": "Louisiana",
                  "index": "28"
              },
              {
                  "code": "ME",
                  "name": "Maine",
                  "index": "29"
              },
              {
                  "code": "MH",
                  "name": "Marshall Islands",
                  "index": "30"
              },
              {
                  "code": "MD",
                  "name": "Maryland",
                  "index": "31"
              },
              {
                  "code": "MA",
                  "name": "Massachusetts",
                  "index": "32"
              },
              {
                  "code": "MI",
                  "name": "Michigan",
                  "index": "33"
              },
              {
                  "code": "MN",
                  "name": "Minnesota",
                  "index": "34"
              },
              {
                  "code": "MS",
                  "name": "Mississippi",
                  "index": "35"
              },
              {
                  "code": "MO",
                  "name": "Missouri",
                  "index": "36"
              },
              {
                  "code": "MT",
                  "name": "Montana",
                  "index": "37"
              },
              {
                  "code": "NE",
                  "name": "Nebraska",
                  "index": "38"
              },
              {
                  "code": "NV",
                  "name": "Nevada",
                  "index": "39"
              },
              {
                  "code": "NH",
                  "name": "New Hampshire",
                  "index": "40"
              },
              {
                  "code": "NJ",
                  "name": "New Jersey",
                  "index": "41"
              },
              {
                  "code": "NM",
                  "name": "New Mexico",
                  "index": "42"
              },
              {
                  "code": "NY",
                  "name": "New York",
                  "index": "43"
              },
              {
                  "code": "NC",
                  "name": "North Carolina",
                  "index": "44"
              },
              {
                  "code": "ND",
                  "name": "North Dakota",
                  "index": "45"
              },
              {
                  "code": "MP",
                  "name": "Northern Mariana Islands",
                  "index": "46"
              },
              {
                  "code": "OH",
                  "name": "Ohio",
                  "index": "47"
              },
              {
                  "code": "OK",
                  "name": "Oklahoma",
                  "index": "48"
              },
              {
                  "code": "OR",
                  "name": "Oregon",
                  "index": "49"
              },
              {
                  "code": "PW",
                  "name": "Palau",
                  "index": "50"
              },
              {
                  "code": "PA",
                  "name": "Pennsylvania",
                  "index": "51"
              },
              {
                  "code": "PR",
                  "name": "Puerto Rico",
                  "index": "52"
              },
              {
                  "code": "RI",
                  "name": "Rhode Island",
                  "index": "53"
              },
              {
                  "code": "SC",
                  "name": "South Carolina",
                  "index": "54"
              },
              {
                  "code": "SD",
                  "name": "South Dakota",
                  "index": "55"
              },
              {
                  "code": "TN",
                  "name": "Tennessee",
                  "index": "56"
              },
              {
                  "code": "TX",
                  "name": "Texas",
                  "index": "57"
              },
              {
                  "code": "UT",
                  "name": "Utah",
                  "index": "58"
              },
              {
                  "code": "VT",
                  "name": "Vermont",
                  "index": "59"
              },
              {
                  "code": "VI",
                  "name": "Virgin Islands",
                  "index": "60"
              },
              {
                  "code": "VA",
                  "name": "Virginia",
                  "index": "61"
              },
              {
                  "code": "WA",
                  "name": "Washington",
                  "index": "62"
              },
              {
                  "code": "WV",
                  "name": "West Virginia",
                  "index": "63"
              },
              {
                  "code": "WI",
                  "name": "Wisconsin",
                  "index": "64"
              },
              {
                  "code": "WY",
                  "name": "Wyoming",
                  "index": "65"
              }
          ]
      },
      "UY": {
          "name": "Uruguay",
          "code": "UY"
      },
      "UZ": {
          "name": "Uzbekistan",
          "code": "UZ"
      },
      "VA": {
          "name": "Vatican City",
          "code": "VA"
      },
      "VC": {
          "name": "St. Vincent & Grenadines",
          "code": "VC"
      },
      "VE": {
          "name": "Venezuela",
          "code": "VE"
      },
      "VG": {
          "name": "British Virgin Islands",
          "code": "VG"
      },
      "VI": {
          "name": "U.S. Virgin Islands",
          "code": "VI"
      },
      "VN": {
          "name": "Vietnam",
          "code": "VN"
      },
      "VU": {
          "name": "Vanuatu",
          "code": "VU"
      },
      "WF": {
          "name": "Wallis & Futuna",
          "code": "WF"
      },
      "WS": {
          "name": "Samoa",
          "code": "WS"
      },
      "YE": {
          "name": "Yemen",
          "code": "YE"
      },
      "YT": {
          "name": "Mayotte",
          "code": "YT"
      },
      "ZA": {
          "name": "South Africa",
          "code": "ZA"
      },
      "ZM": {
          "name": "Zambia",
          "code": "ZM"
      },
      "ZW": {
          "name": "Zimbabwe",
          "code": "ZW"
      }
    },
}