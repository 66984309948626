import Vue from 'vue'
import Vuex from 'vuex'
import Api from '@/api';
import { Loading, Message ,MessageBox} from 'element-ui';
Vue.use(Vuex);

// 操作名称
export const CREATE_WORK_ORDER = 'CreateWorkOrder';
export const SAVE_WORK_ORDER = 'SaveWorkOrder';
export const UPDATE_WORK_ORDER = 'UpdateWorkOrder';
export const SUBMIT_WORK_ORDER = 'SubmitWorkOrder';
export const APPROVE_WORK_ORDER = 'ApproveWorkOrder';
export const GET_WORK_ORDER = 'GetWorkOrderData';

// 操作码
export const MSG_A = 'A';
export const MSG_B = 'B';
export const MSG_C = 'C';
export const MSG_D0 = 'D0';  // DHL邮箱未进行校验 或者 校验没通过。仍和修改dhl邮箱
export const MSG_D1 = 'D1';  // DHL邮箱通过校验 不能修改DHL邮箱
export const MSG_D2 = 'D2';  // 退货通知单已经生成
export const MSG_D3 = 'D3';  // 发货通知单已经生成 整个表单 结束

// 工单状态
export const STATUS_A = 'A';
export const STATUS_B = 'B';
export const STATUS_C = 'C';
export const STATUS_D = 'D';
export const STATUS_E = 'E';
export const STATUS_F = 'F';
export const STATUS_G = 'G';
export const STATUS_H = 'H';
export const STATUS_I = 'I';



function generateErrorTipText(operaName , errCode){
  const translateText = {
    'E100' : 'Error : Wrong Status',
    'E200' : 'Error : Unknown Token ',
    [`${SAVE_WORK_ORDER}_E01`] : 'Error: Ticket has been exist',
    [`E02`] : 'Error: Ticket is not exist',
    [`${SUBMIT_WORK_ORDER}_E03`] : 'Submission Failed; Error: Failed to send email',
    [`${APPROVE_WORK_ORDER}_E04`] : `Submission Failed; Error: Wrong Verification Code`,
    'request':'Error : Request with errors',
    '验证码不对，无法FAE提交': 'Submission Failed; Error: Wrong Verification Code',
    '邮件发送失败，请重试': 'Submission Failed; Error: Failed to send email',
    '状态不对，无法FAE提交': 'Submission Failed; Error: Wrong Status',
    '未查询到该工单数据': 'Error: Unknown Ticket Data',
    'Token解析失败' : 'Error: Unknown Token',
    '状态不对，无法保存':'Saved failed; Error: Wrong Status'
  }
  return translateText[operaName+'_'+errCode] || translateText[errCode] || (errCode ? 'Error Code : ' + errCode : 'Error')  ;

}



export const $msg = (type, message) => {
  let icon = '';
  switch (type) {
    case 'error': icon = 'el-icon-error'; break;
    case 'success': icon = 'el-icon-success'; break;
  }
  message && type && Message[type]({
    message,
    center: true,
    icon,
    duration: 3500,
    offset: 100,
    dangerouslyUseHTMLString: true,
  })
}




const $loading = () => {
  return Loading.service({
    lock: false,
    background: 'rgba(0,0,0,.4)'
  });
}

export default new Vuex.Store({
  state: {
    messageCode: '',        // 操作码,
    isVerifySn: false,        //  是否通过sn校验
    isReadedNotice: false,   // 是否阅读了notice
    ticketData : {},         // 从服务器拿到的表单数据
    formData: {
      SNCode: "",             //  SN码
      WorkOrderNo : "",       //  工单号

      /* 支持渠道 */
      SupportChannelType: "", //  支持渠道类型
      SupportChannelText: "", //  支持渠道

      /* 购买渠道 */
      BuyChannelType: "",  //  购买渠道 类型
      OrderNo: "",         //  订单号
      BuyScreenShot: "",   //  购买截图

      /* 问题描述 */
      FaultType: "",                  // 故障类型
      FaultAccessoryType: "",         // 故障配件类型
      FaultOtherText: "",             // 其它故障类型
      ProblemDescription: "",         // 故障描述
      Picture: [{PictureUrl: "" }],  // 故障描述图片

      /* 售后 */
      AfterSaleType: "",    // 售后类型
      IsReplaceReturn: "",  // 换新是否寄回
      SendAccessory: "",    // 补发配件
      AntennaModel: "",     // 补发天线型号
      OtherAccessory: "",   // 其它配件
      ReturnPayer: "",      // 寄回运费承担人

      /* 收件人信息 */
      RecMan: "",           // 收件人
      RecCountryCode: "",   // 国家
      RecState: "",         // 省
      RecCity: "",          // 城市
      RecCompany: "",       // 公司
      RecAddr: "",          // 地址详情
      RecPostCode: "",      // 邮编
      RecPhone: "",         // 电话
      RecEmail: "",         // Email

      DHLEmail: "",         // DHL邮箱
      SendTrackingNo: "",   // 寄件单号

      Status: [],
    },

    visibleTrackingAlert : false, // 提示用户提交tracking number 成功
    visibleTicketStatusPanel : false, // 
  },
  getters: {
    // 表单数据
    FORM_DATA: state => state.formData,

    // 服务器的表单数据
    TICKET_DATA : state => state.ticketData,

    // 操作码
    MESSAGE_CODE: state => state.messageCode,

    // 工单状态
    ORDER_STATUS: state => state.formData.Status ? [...new Set(state.formData.Status.map(item => item.StatusCode))] : [],

    // SN校验是否通过
    IS_VERIFY_SN: state => state.isVerifySn,

    // 商品是否需要寄回
    IS_NEED_RETURN: state => {
      return (state.formData.AfterSaleType === 'Replace' && parseInt(state.formData.IsReplaceReturn) === 1) || state.formData.AfterSaleType === 'Repair'
    },

    // 商品需要维修？
    IS_NEED_REPAIR: state => {
      return state.formData.AfterSaleType === 'Repair'
    },

    // 退费是否是seeed来出
    IS_SEEED_PAYEE: state => {
      return state.formData.ReturnPayer === 'Seeed'
    },

    // DHL 是否被通过
    IS_DHL_APPROVED : (state,getters) => {
      return getters.ORDER_STATUS.findIndex(item => item === 'C') >= 0;
    },
    

    // 是否阅读过notice
    IS_READED_NOTICE: state => {
      return state.isReadedNotice
    },

    // 是否有提交过dhl
    HAS_SUBMIT_DHL: state => {return state.ticketData.DHLEmail},

    // 是否提交了tracking number
    HAS_SUBMIT_TRACKING: state => {return state.ticketData.SendTrackingNo},

    VISIBLE_TRACKING_ALERT :state => {  return state.visibleTrackingAlert },
    VISIBLE_TICKET_STATUS_PANEL: state => {  return state.visibleTicketStatusPanel },

    IS_DEV : state =>  process.env.NODE_ENV === 'development'
  },

  mutations: {

    /* 更新表单 */
    updateFormData(state, data) { state.formData = { ...data } },

    /* 更新ticketData */
    updateTicketData(state, data) { state.ticketData = { ...data } },


    /* 更新操作码 */
    updateMessageCode: (state, code) => {
      state.messageCode = code;
    },

    /* setVerifySn */
    setVerifySn: (state, result) => {
      state.isVerifySn = result;
    },

    // 勾选阅读notice
    readNotice: (state, value) => { state.isReadedNotice = value },

    setVisibleTrackingAlert : (state , value) => {console.log(value);state.visibleTrackingAlert = Boolean(value)},
    setVisibleTicketStatusPanel :(state ,value) => {state.visibleTicketStatusPanel = Boolean(value)}
  },
  actions: {

    /* 校验SN码 */
    verifySn({ commit, getters, dispatch }, value) {
      let sn = getters.FORM_DATA.SNCode;
      if (value != sn) {
        commit('setVerifySn', false)
        $msg('error', `Invalid Serial Number(SN)`)
      } else {
        commit('setVerifySn', true);
        $msg('success', `Verified successfully! `)
      }
    },

    /* 获取工单数据 */
    async getWorkOrderData({ commit, getters, dispatch }) {
      let loading = $loading();
      try {
        let res = await Api.getWorkOrder();
        if (res.Success === 'true') {
          // 更新操作码
          commit('updateMessageCode', res.MessageCode);
          if (res.MessageCode !== MSG_A && res.Result) {
            commit('updateFormData', res.Result);
            commit('updateTicketData',res.Result)
          } else {
            // commit('updateFormData',JSON.parse(localStorage.getItem('formData')))
          }

          // 根据状态判断 
          !getters.IS_VERIFY_SN && commit('setVerifySn', res.MessageCode === MSG_A);
          loading.close();
          return true;
        } else {
          loading.close();
          dispatch('setTip',{ isSuccess : false,  operaName : GET_WORK_ORDER , errText :res.MessageCode || res.Message })
          return false;
        }
      } catch (err) {
        loading.close();
        dispatch('setTip',{ isSuccess : false,  operaName : GET_WORK_ORDER , errText :'request'})
        return false;
      }
    },

    /* 获取操作码 */
    async updateMsgCode({ commit }) {
      let loading = $loading();
      try {
        let res = await Api.getWorkOrder();
        if (res.Success === 'true') {
          // 更新操作码
          commit('updateMessageCode', res.MessageCode);
          loading.close();
          return true;
        } else {
          dispatch('setTip',{ isSuccess : false,  operaName : GET_WORK_ORDER , errText :res.MessageCode || res.Message })
          loading.close();
          return false;
        }
      } catch (err) {
        loading.close();
        dispatch('setTip',{ isSuccess : false,  operaName : GET_WORK_ORDER , errText :'request'})
        return false;
      }
    },


    /* 更新操作码 */
    updateMessageCode({ commit, getters, actions }, statusCode) {
      commit('updateMessageCode', statusCode)
    },


    /* 工单操作 */
    async operateWorkOrder({ commit, getters, dispatch }, payload) {
      let loading = $loading();
      // let isUpdateMsgCode = await dispatch('updateMsgCode');
      // if(!isUpdateMsgCode) return ;
      let { name, formData ,operaType} = payload;
      delete formData.Status;
      try {
        let res = await Api.operaWorkOrder(name, formData);
        loading.close();
      
        if (res.Success === "true") {
          commit('setVisibleTrackingAlert',!getters.HAS_SUBMIT_TRACKING && formData.SendTrackingNo);
          !getters.VISIBLE_TRACKING_ALERT &&  dispatch('setTip',{ isSuccess : true,  operaName : name ,formData,operaType})
          return true;
        } else {
          dispatch('setTip',{ isSuccess : false,  operaName : name ,errText : res.MessageCode || res.Message ,operaType})
          return false;
        }
      } catch (err) {
        console.log(err)
        loading.close();
        dispatch('setTip',{ isSuccess : false,  operaName : name ,errText : 'request' ,operaType})
        return false;
      }
    },

    setTip({ getters }, payload) {
      let { operaName, isSuccess } = payload;
      let errText = payload.errText || '';
      let operaType = payload.operaType || '';
      let msgCode = getters.MESSAGE_CODE;
      let tipContent = '';
      if (!isSuccess) { 
        tipContent = generateErrorTipText(operaName , errText);
      }else{
        switch (operaName) {
          case CREATE_WORK_ORDER:
            switch(operaType){
              case 'create2submit': tipContent = '';break;
              case 'create=save' :  tipContent = 'Your ticket has been temporally saved.<br/>Please complete all the required content and submit.';break;
            }
            break;
          case SAVE_WORK_ORDER:
            switch(operaType){
              case 'save'       : tipContent = 'Your ticket has been temporally saved.<br/>Please complete all the required content and submit.';break;
              case 'save2submit': tipContent = '';break;
              case 'save=submit' :  tipContent = 'Your ticket has been submitted successfully.<br/> Please wait for the review and approval from tech suport. Thanks!';break;
            }
            break;
          case SUBMIT_WORK_ORDER:
            tipContent = 'Your ticket has been submitted successfully.<br/> Please wait for the review and approval from tech suport. Thanks!';
            break;
          case UPDATE_WORK_ORDER:
            tipContent = 'Your ticket has been modified successfully.<br/> ';
            break;
          case APPROVE_WORK_ORDER:
            tipContent = 'Ticket has been approved successfully';break;
        }
      }
      tipContent && $msg(isSuccess ? 'success': 'error' , `${tipContent}`)

    },

    /* FAE 审核 */
    async submitFAEForm({ commit, getters, dispatch }, data) {
      let loading = $loading();
      try {
        let res = await Api.approveWorkOrder(data);
        loading.close();
        if (res.Success === "true") {
          dispatch('setTip',{ isSuccess : true,  operaName : APPROVE_WORK_ORDER})
          dispatch('getWorkOrderData');
          return true;
        } else {
          dispatch('setTip',{ isSuccess : false,  operaName : APPROVE_WORK_ORDER , errText :res.MessageCode || res.Message})
          return false;
        }
      } catch (err) {
        loading.close();
        $msg('error', `Failed`)
        dispatch('setTip',{ isSuccess : false,  operaName : APPROVE_WORK_ORDER , errText : 'request'})
        return false;
      }
    },


  },
  modules: {}
})