<template lang="">
<div class="order-status-container" >
   <div class="bar-ticket-number" v-if="FORM_DATA.WorkOrderNo">Aftersale Ticket Number : <span>{{FORM_DATA.WorkOrderNo}}</span> </div>
  <el-steps finish-status="success" process-status="process" align-center class="order-steps">
    <el-step v-for="(item,index) in status" :title="item['title']" :status="getStepType(item,index)" ></el-step>
  </el-steps>
  
  <div class="show-order-status-panel" v-if="visiblePanelControl" style="text-align:center"> 
    <div class="show-btn"  @click="setVisibleTicketStatusPanel(true)"> Check Ticket Status<i class="el-icon-caret-bottom"></i></div>
  </div>
</div>
</template>
<script>
import { mapGetters ,mapMutations} from "vuex";
import {
    MSG_A,
    MSG_B,
    MSG_C,
    MSG_D0,
    MSG_D1,
    MSG_D2,
    MSG_D3,
    } from "@/store/index";
import allStatus from "./status"
export default {
  data(){return{

  }},
  computed:{
    ...mapGetters([
      'ORDER_STATUS',
      'FORM_DATA',
      'IS_SEEED_PAYEE',
      'IS_NEED_RETURN',
      'IS_NEED_REPAIR',
      'MESSAGE_CODE'
    ]),
    visiblePanelControl(){
      return this.MESSAGE_CODE && this.MESSAGE_CODE !== MSG_A && this.MESSAGE_CODE !== MSG_B
    },
    status(){
      let len = this.ORDER_STATUS.length ;
      let data = [...allStatus]
        
      if(!this.IS_NEED_RETURN){
        data = data.filter(item => {return item.key !=='C' && item.key !== 'D' && item.key !== 'E' && item.key !== 'F'} )
      }else{
        if(!this.IS_SEEED_PAYEE){
         data = data.filter(item => {return item.key !== 'C'} )
        }
      }
    /*   if(!this.IS_NEED_REPAIR){2
       data = data.filter(item => {return item.key !== 'F'} )
      } */
      
      return data;
    },
    activeIndex(){
      let len = this.ORDER_STATUS.length ;
      if(len === 0 ) return -1;
      let last =  this.ORDER_STATUS[len-1];
      if(last === 'A' && (this.MESSAGE_CODE === MSG_A || this.MESSAGE_CODE === MSG_B)){
        return -1;
      }

      let acindex = 0;
      let p = (arr,len)=>{
        let last = arr[len -1 ];
        acindex = this.status.findIndex(item => item.key === last || (item.include?.length && item.include.indexOf(last) >= 0 )) ;
        if(acindex < 0){
          len -= 1;
          if(len > 0 ){
            p(arr,len)
          }else{
            return 0
          }
        }else{
         return acindex;
        }
      }

      try{
        p(this.ORDER_STATUS,len);
      }catch(err){
        acindex = this.status.findIndex(item => item.key === last);
      }
      return acindex;
    },
    
  },
  methods:{
    ...mapMutations(['setVisibleTicketStatusPanel']),
    getStepType(item,index){
      let activeIndex = this.activeIndex;
      let statusArr = this.status ;
      if(index <= activeIndex  ){
        return 'success'
        // return this.ORDER_STATUS.findIndex(i => i === item.key) >= 0 ?  'success' : 'process'
      }else if(index === activeIndex+1){
        return 'process'
      }else{
        return ''
      }
    }
  }
}
</script>
<style lang="scss">

  .bar-ticket-number{
    font-size: 16px;
    font-weight: bold;
    color: #000;
    padding: 0;
    margin: 0 0 15px 0;
    text-align:center;
    span{color: #00946c;};
  }
  .show-order-status-panel{
    padding: 10px 0  0 0;
    text-align: center;
      color: #00946c;
      font-size: 13px;
      font-weight: bold;
    
    .show-btn{
      display: inline-block;
      width: auto;
      margin: 0 10px;
      text-decoration: underline;
      cursor: pointer;
      i{
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
  .order-status-container{
    padding: percentage(10 / 1200) 0 percentage(10 / 1200) 0;
    position: sticky;
    top: 0;
    bottom: unset;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    box-shadow: 0 0px 5px #ccc;
    z-index: 100;
    margin-bottom: percentage(30 / 1200);
  }
  .order-steps{
    max-width: 1200px;
    @media screen and (max-width:1080px) {
          max-width: 100%;
      }
    margin: 0 auto;
    .el-step__head.is-process{
        color: #000;
        border-color: #000;
    }
    .el-step__title{
      font-size: 12px;
      padding: 0 4px 5px;
      margin: 6px auto 0 auto;
      text-align: center;
      max-width: 130px;
      color: #333;
      font-weight: normal;
      font-weight: bold;
      line-height: 16px;
      text-transform: capitalize;
      @media screen and (max-width:460px) {
        font-size: 9px;
        padding: 0 1px 3px;
        line-height: 13px;
      }
      &.is-process{
        color: #000;
        border-color: #000;
      }
    }
    .el-step__icon{
      @media screen and (max-width:460px) {
        width: 16px;
        height: 16px;
        font-size: 8px;
      }
    }
    .el-step__head.is-success{
      .el-step__icon{
        background: #00946c;
        color: #fff;
      }
      .el-step__line{
         background: #00946c;
      }
      

    }
    .el-step__head.is-process{
      .el-step__icon{background-color: #fff;color: #000;border-color: #000;}
    }
  }
</style>