export default {
    validateImg : (rule ,value ,cb ,test)=>{
        if(!/\S+/.test(value)){return cb(); } 
        let img = new Image();
          img.onerror = ()=>{return cb(new Error('Image loaded failed')); }
          img.onload = ()=>{return cb()}
          img.src = value;
    },
    validateSN:(rule ,value ,cb )=>{
        value = value.replace(/^\s+|\s+$/,'');
        if(value.length !== 18 || !/^1/.test(value)){
            return cb(new Error('This field must be 18 characters and start with 1'));
        }
        return cb(); 
    },
    validateSeeedOrder:(rule ,value ,cb ,source,options)=>{
        value = value.replace(/^\s+|\s+$/,'');
        if(!/^\d{10,11}$/.test(value)){
            return cb(new Error('This field must be 10 or 11 numbers'));
        }
        return cb(); 
    },
    validateDhl : (rule,value,cb) => {
        value = value.replace(/^\s+|\s+$/,'');
        if(!/^\d{10}$/.test(value)){
            return cb(new Error('This field must be 10 numbers'))
        }
        return cb(); 
    },
    validateSHGD :(rule ,value ,cb)=>{
        value = value.replace(/^\s+|\s+$/,'');
        if(!value.length){ return cb(new Error('Your Discord ID and Discord Ticket Number (if any)'))}
        if(/SHGD/ig.test(value)){
            return cb(new Error('Please enter your Discord ID and the 4-digit Discord Ticket Number, not SenseCAP Aftersale Ticket Number.'))
        }
        return cb(); 
    } 
}